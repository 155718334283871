import { FC, ReactNode } from 'react';
import { Sidebar } from './sidebar';
import { TopBar } from './topbar';

interface IDashboardLayout {
  children: ReactNode | ReactNode[];
}

export const DashboardLayout: FC<IDashboardLayout> = ({ children }) => {
  return (
    <>
      <main className="flex h-screen w-screen">
        <Sidebar />
        <div className="flex-grow flex flex-col w-screen lg:w-auto overflow-x-hidden ease-in-out transition-all duration-300">
          <TopBar />
          <div className="flex-grow bg-grey-white flex flex-col h-full overflow-y-auto">
            {children}
          </div>
        </div>
      </main>
    </>
  );
};
