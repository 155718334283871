/* eslint-disable array-callback-return */
import moment from 'moment';
import { SyntheticEvent } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { storeRef } from '..';
import { Option, OrderStatus } from '../types';

export const onGetInputValue = <T>(
  event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>,
  type: 'string' | 'number',
  payload: T,
  setPayload: (x: T) => any
) => {
  const value = event.currentTarget.value;
  const name = event.currentTarget.name;

  setPayload({
    ...payload,
    [name]: type === 'number' ? parseInt(value) : value,
  });
};

export const onGetMultiSelectValue = <X, Y>(
  value:
    | MultiValue<{ value: Y; label: string | undefined }>
    | SingleValue<{ value: any; label: any }>,
  name: string,
  payload: X,
  setPayload: (x: X) => any
) => {
  if (Array.isArray(value)) {
    setPayload({
      ...payload,
      [name]: value.map((item) => ({
        id: item.value,
      })),
    });
  }
};

export const genQuery = ({
  range,
  dateFrom,
  query,
  displayCount,
  status,
  notstatus,
}: {
  range?: string | { startDate: Date | null; endDate: Date | null };
  dateFrom?: { from: string | null; till?: string | null };
  displayCount?: number;
  query?: string;
  status?: OrderStatus[];
  notstatus?: OrderStatus[];
}) => {
  let generatedQuery = '';

  if (displayCount) {
    generatedQuery += `&limit=${displayCount}`;
  }

  if (typeof range === 'string') {
    generatedQuery += `&dateRange=${range}`;
  }

  if (dateFrom && dateFrom?.from)
    generatedQuery += `?from=${moment(dateFrom.from)
      .set({ hour: 1, minute: 0 })
      .toISOString()}`;

  if (dateFrom && dateFrom.till)
    generatedQuery += `&till=${moment(dateFrom.till)
      .set({ hour: 24, minute: 59 })
      .toISOString()}`;

  if (range && typeof range !== 'string')
    generatedQuery += `&dateRange[0]=${moment(range.startDate).format(
      'YYYY-MM-DD'
    )}&dateRange[1]=${moment(range.endDate).format('YYYY-MM-DD')}`;

  if (query) generatedQuery += `&kitchenId=${query}`;

  if (status && status.length > 0)
    generatedQuery += `&${status.map((st) => `status[]=${st}`).join('&')}`;

  if (notstatus && notstatus.length > 0)
    generatedQuery += `&${notstatus
      .map((st) => `notstatus[]=${st}`)
      .join('&')}`;

  return generatedQuery;
};

export const genFilterQuery = () => {
  const { filter } = storeRef.getState();
  const { module } = filter;
  const activeModule = filter[module];
  const { startDate, endDate } = activeModule.dateRange;

  let query = '';

  startDate && (query += `&from=${moment(startDate).toISOString()}`);
  endDate && (query += `&till=${moment(endDate).toISOString()}`);

  activeModule.provider && (query += `&provider=${activeModule.provider}`);

  activeModule.transactionType &&
    (query +=
      activeModule.transactionType === 'credit'
        ? `&amount[gt]=0`
        : `&amount[lt]=0`);

  activeModule.amount &&
    activeModule.amount.max &&
    (query += `&amount[lte]=${activeModule.amount.max}`);

  activeModule.amount &&
    activeModule.amount.min &&
    (query += `&amount[gte]=${activeModule.amount.min}`);

  return query;
};

export const formatDate = (date: Date | string) => {
  return typeof date === 'string'
    ? date
    : [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-');
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const isObjectEmpty = (obj: object) => {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
};

export const stringifyParameters = (params: any) => {
  return !params || isObjectEmpty(params)
    ? ''
    : `?${Object.keys(params)
        .map((key) => {
          if (typeof params[key] === 'object') {
            return params[key]
              .map((item: string) => `${key}[]=${item}`)
              .join('&');
          } else if (params[key]) {
            return `${key}=${params[key]}`;
          }
        })
        .join('&')}`;
};

export const getOptionPrice = (option: Option) =>
  option.price !== null ? option.price : option.sameAsFoodItem?.price ?? 0;
