import { Cell } from 'react-table';

export const branchColumn = [
  {
    Header: 'Branch Name',
    accessor: 'name',
  },
  {
    Header: 'Brand',
    accessor: 'brand',
    Cell: ({ row }: Cell) => row.values.brand.name,
  },
  {
    Header: 'Kitchen',
    accessor: 'kitchen',
    Cell: ({ row }: Cell) => row.values.kitchen.name,
  },
  {
    Header: 'Action',
  },
];

export const mealOverrideColumns = [
  {
    Header: 'Meal',
    accessor: 'name',
  },
  {
    Header: 'Brand',
    accessor: 'brand',
    Cell: ({ row }: Cell) => {
      if (row.values.brand) {
        return row.values.brand.name;
      } else {
        return '';
      }
    },
  },
  {
    Header: 'Is Available?',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Price',
    accessor: 'basePrice',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Quick Actions',
  },
];

export const variantOverrideColumns = [
  {
    Header: 'Variant',
    accessor: 'name',
  },
  {
    Header: 'Is Available?',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Quick Actions',
  },
];
