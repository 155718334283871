import { IBranchApi } from '../types';
import { instance } from './instance';

export const branchApi: IBranchApi = {
  getAllBranches: async () => await instance.get('/branch/all/'),
  createBranch: async (params) => await instance.post('/branch/', params),
  updateBranch: async (params, id) =>
    await instance.patch(`/branch/${id}/overrides`, params),
  getBranchMenu: async (id) => await instance.get(`/branch/${id}/menu/all`),
  updateBranchName: async (payload, id) =>
    await instance.patch(`/branch/${id}`, payload),
};
