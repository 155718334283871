import React from 'react';
import { close as closeIcon } from '../../../assets/svg';
import { useAppDispatch } from '../../../hooks';
import {
  exportOrder,
  exportUsers,
  sendFoodItemsReport,
} from '../../../store/slices';
import { PrimaryButton, SecondaryButton } from '../shared';
import moment from 'moment';
import { formatDate } from '../../../helpers';

export const ExportCsv = ({
  module = 'orders',
  toggle,
}: {
  module?: 'orders' | 'users';
  toggle: () => void;
}) => {
  const dispatch = useAppDispatch();

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const [filter, setFilter] = React.useState({
    from: formatDate(yesterday),
    till: formatDate(today),
  });

  const exportOrders = () => {
    if (module === 'users') {
      dispatch(exportUsers({ payload: filter, cb: toggle }));
    } else {
      dispatch(exportOrder({ payload: filter, cb: toggle }));
    }
  };

  const exportFoodItems = () => {
    dispatch(sendFoodItemsReport({ payload: filter, cb: toggle }));
  };

  const handleDatePicker = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    setFilter({ ...filter, [name]: value });
  };

  return (
    <div className="bg-white p-6">
      <div className="flex justify-between items-center">
        <p className="bold text-2xl">Export {module} as CSV</p>
        <button onClick={toggle}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>

      <div className="mt-6">
        <div className="flex flex-col space-y-1">
          <label className="bold text-sm" htmlFor="from">
            From:
          </label>
          <input
            type="date"
            id="from"
            value={moment(filter.from).format('YYYY-MM-DD')}
            onChange={handleDatePicker}
            className="focus:outline-none"
            name="from"
          />
        </div>

        <div className="flex flex-col space-y-1 mt-6">
          <label className="bold text-sm" htmlFor="till">
            To:
          </label>
          <input
            type="date"
            id="till"
            value={moment(filter.till).format('YYYY-MM-DD')}
            onChange={handleDatePicker}
            className="focus:outline-none"
            name="till"
          />
        </div>
      </div>

      <div className="mt-6 justify-between flex">
        <PrimaryButton onClick={exportOrders} text={`Export ${module}`} />
        {module === 'orders' && (
          <SecondaryButton onClick={exportFoodItems} text="Export food items" />
        )}
      </div>
    </div>
  );
};
