import { Cell } from 'react-table';
import { statusColors } from './orders.constants';

export const couponsColumns = [
  {
    Header: 'Coupon Code',
    accessor: 'code',
  },
  {
    Header: 'Coupon Status',
    accessor: 'enabled',
    Cell: ({ row }: Cell) => {
      if (row.values.enabled) {
        return (
          <div className="flex items-center space-x-4 uppercase">
            <div
              className={`h-2 w-2 rounded-full mr-2 ${
                statusColors['Enabled'] || 'bg-pink'
              }`}
            />{' '}
            Enabled
          </div>
        );
      } else {
        return (
          <div className="flex items-center space-x-4 uppercase">
            <div
              className={`h-2 w-2 rounded-full mr-2 ${
                statusColors['Disabled'] || 'bg-pink'
              }`}
            />{' '}
            Disabled
          </div>
        );
      }
    },
  },
  {
    Header: 'Type',
    accessor: 'isPercent',
    Cell: ({ row }: Cell) => {
      if ((row.original as any).isPercent) {
        return 'Percentage';
      }
      if ((row.original as any).freeDelivery) {
        return 'Free Delivery';
      }
      return 'Discount Amount';
    },
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
  },
  {
    Header: 'Action',
  },
];

export const couponStatus = [
  { value: '0', label: 'Disabled' },
  { value: '1', label: 'Enabled' },
];

export const couponType = [
  { value: 'percent', label: 'Percent' },
  { value: 'amount', label: 'Discount Amount' },
  { value: 'delivery', label: 'Free Delivery' },
];
