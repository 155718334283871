import { IUser } from "../types";

const returnUsername = (id: number, users: IUser[]) => {
  let username;
  for (let i = 0; i < users.length; i++) {
    if (users[i].id === id) {
      username = users[i].username;
    }
  }
  return username;
};

const userFilter = (option: any, searchText: string) => {
  if (
    option.data.value.phoneNumber
      .toLowerCase()
      .includes(searchText.toLowerCase()) ||
    option.data.value.lastName
      .toLowerCase()
      .includes(searchText.toLowerCase()) ||
    option.data.value.email.toLowerCase().includes(searchText.toLowerCase()) ||
    option.data.value.firstName.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return true;
  } else {
    return false;
  }
};

const customerFilter = (option: any, searchText: string) => {
  if (
    option.data.value.phoneNumber
      .toLowerCase()
      .includes(searchText.toLowerCase()) ||
    option.data.value.fullName
      .toLowerCase()
      .includes(searchText.toLowerCase()) ||
    option.data.value.email.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return true;
  } else {
    return false;
  }
};

const userCustomerFilter = (option: any, searchText: string) => {
  if (
    option.data.value.phoneNumber
      .toLowerCase()
      .includes(searchText.toLowerCase()) ||
    option.data.value.fullName
      .toLowerCase()
      .includes(searchText.toLowerCase()) ||
    option.data.value.email.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return true;
  } else {
    return false;
  }
};

export { returnUsername, userFilter, customerFilter, userCustomerFilter };
