import { why500 } from '../../../constants';

export const WhyChoose = () => {
  return (
    <div className="mt-32">
      <p className="passion-bold text-center text-black text-4xl sm:text-[80px] uppercase leading-[100%]">
        Why Choose <br /> 500chow
      </p>

      <div className="flex flex-wrap horizontal w-10/12 mx-auto justify-center">
        {why500.map((option) => (
          <div
            key={option.title}
            className="flex flex-col items-center flex-shrink-0 w-3/12 mr-14 sm:mx-16 mt-16"
          >
            <img src={option.image} alt="cover" className="h-[300px]" />
            <p className="mt-7 text-2xl sm:text-5xl passion-bold text-primary-brown uppercase text-center whitespace-nowrap">
              {option.title}
            </p>
            <p className="mt-2 sora-light text-xs sm:text-2xl sm:leading-[40px] text-center w-full mx-auto">
              {option.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
