import { configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/auth.slice';
import brandsReducer from './slices/brands.slice';
import kitchensReducer from './slices/kitchens.slice';
import variantsReducer from './slices/variant.slice';
import mealsReducer from './slices/meals.slice';
import ordersReducer from './slices/orders.slice';
import categoriesReducer from './slices/categories.slice';
import usersReducer from './slices/users.slice';
import pilotsReducer from './slices/pilots.slice';
import promotionsReducer from './slices/promotions.slice';
import generalReducer from './slices/general.slice';
import couponReducer from './slices/coupons.slice';
import dashboardReducer from './slices/dashboard.slice';
import transactionReducer from './slices/transactions.slice';
import filterReducer from './slices/filter.slice';
import branchReducer from './slices/branch.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    brands: brandsReducer,
    kitchens: kitchensReducer,
    variants: variantsReducer,
    meals: mealsReducer,
    orders: ordersReducer,
    categories: categoriesReducer,
    users: usersReducer,
    pilots: pilotsReducer,
    promotions: promotionsReducer,
    general: generalReducer,
    coupons: couponReducer,
    dashboard: dashboardReducer,
    transactions: transactionReducer,
    filter: filterReducer,
    branch: branchReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
