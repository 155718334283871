import React from 'react';
import { FoodItem, Option, ResolvedResponse } from '../types';
import { useFetch } from './useFetch';
import { IOnChange } from '../views/components';
import { mealsApi } from '../api';
import { toast } from 'react-toastify';
import { parseError } from '../helpers';

export const useOptions = () => {
  const [modal, setModal] = React.useState(false);
  const [option, setOption] = React.useState<Option>({ name: '', price: 0 });

  const { useFetchOptions, useFetchFoodItem } = useFetch();

  const { data: options } = useFetchOptions();
  const { data: foodItems } = useFetchFoodItem<ResolvedResponse<FoodItem[]>>(
    undefined,
    { count: 500 }
  );

  const onGetInputValue = (
    event: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    if (name === 'price') {
      return setOption({
        ...option,
        price: value === '' ? null : parseInt(value),
      });
    }

    setOption({
      ...option,
      [name]: value,
    });
  };

  const closeModal = () => {
    setModal(false);
    setOption({ name: '', price: 0 });
  };

  const editOption = (id: string) => {
    const o = options?.data?.find((o) => o.id === id);

    if (o) {
      setModal(true);
      setOption({
        ...o,
        foodItem: o.sameAsFoodItem?.id,
      });
    }
  };

  const handleMultiSelect: IOnChange<FoodItem> = (items) => {
    setOption({
      ...option,
      foodItem: (items as FoodItem).id,
    });
  };

  const createOption = async (e?: React.SyntheticEvent<HTMLFormElement>) => {
    e?.stopPropagation();
    e?.preventDefault();

    try {
      if (option.id) {
        await mealsApi.updateOption(option.id!, option);

        toast.success('Option updated!');
      } else {
        await mealsApi.createOption(option);

        toast.success('Option created!');
      }

      closeModal();
    } catch (error) {
      toast.error(parseError(error));
    } finally {
    }
  };

  return {
    modal,
    option,
    options,
    foodItems,
    setModal,
    closeModal,
    editOption,
    createOption,
    onGetInputValue,
    handleMultiSelect,
  };
};
