import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { filterSelector, updateDateRange } from '../../../../store/slices';

export const FilterDateRange = ({
  filterModal = true,
}: {
  filterModal?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { module, ...filter } = useAppSelector(filterSelector);
  const { startDate, endDate } = filter[module].dateRange;

  const setDateRange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    dispatch(updateDateRange({ [name]: value }));
  };

  return (
    <div
      className={`p-2 px-7 ${filterModal ? 'border-r border-neutral-200' : ''}`}
    >
      {filterModal && <h5 className="text-sm semibold">Date Range</h5>}
      <div className={`${filterModal ? 'mt-7 space-y-3' : 'flex space-x-3'}`}>
        <div className="flex flex-col">
          <label className="text-xs text-neutral-500" htmlFor="startDate">
            Start Date
          </label>
          <input
            type="date"
            onChange={setDateRange}
            className="focus:outline-none bg-transparent"
            value={startDate}
            name="startDate"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-xs text-neutral-500" htmlFor="endDate">
            End Date
          </label>
          <input
            type="date"
            onChange={setDateRange}
            className="focus:outline-none bg-transparent"
            value={endDate}
            name="endDate"
          />
        </div>
      </div>
    </div>
  );
};
