import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { dashboardApi } from '../../api/dashboard.api';
import { genQuery } from '../../helpers';
import {
  IBrand,
  ICustomer,
  ICustomerSummary,
  IKitchen,
  ILocation,
  IMeal,
  IUser,
} from '../../types';
import { IOrderSummary, IRevenueSummary, IUserSummary } from '../../types';

interface IDashboardState {
  loading: boolean;
  orderSummary: IOrderSummary | null;
  userSummary: IUserSummary | null;
  customerSummary: ICustomerSummary | null;
  filteredOrderSummary: IOrderSummary | null;
  revenueSummary: IRevenueSummary | null;
  topKitchens: IKitchen[];
  topLocations: ILocation[];
  topMeals: IMeal[];
  topBrands: IBrand[];
  topCustomers: ICustomer[];
  topUsers: IUser[];
}

const initialState: IDashboardState = {
  loading: false,
  orderSummary: null,
  customerSummary: null,
  userSummary: null,
  filteredOrderSummary: null,
  revenueSummary: null,
  topKitchens: [],
  topLocations: [],
  topMeals: [],
  topBrands: [],
  topCustomers: [],
  topUsers: [],
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrderSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderSummary.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.orderSummary = payload;
    });
    builder.addCase(getOrderSummary.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUserSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserSummary.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.userSummary = payload;
    });
    builder.addCase(getUserSummary.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCustomerSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCustomerSummary.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.customerSummary = payload;
    });
    builder.addCase(getCustomerSummary.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getFilteredOrderSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFilteredOrderSummary.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.filteredOrderSummary = payload;
    });
    builder.addCase(getFilteredOrderSummary.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getRevenueSummary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRevenueSummary.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.revenueSummary = payload;
    });
    builder.addCase(getRevenueSummary.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTopKitchens.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTopKitchens.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.topKitchens = payload;
    });
    builder.addCase(getTopKitchens.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTopLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTopLocations.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.topLocations = payload;
    });
    builder.addCase(getTopLocations.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTopMeals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTopMeals.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.topMeals = payload;
    });
    builder.addCase(getTopMeals.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTopBrands.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTopBrands.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.topBrands = payload;
    });
    builder.addCase(getTopBrands.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTopCustomers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTopCustomers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.topCustomers = payload;
    });
    builder.addCase(getTopCustomers.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTopUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTopUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.topUsers = payload;
    });
    builder.addCase(getTopUsers.rejected, (state) => {
      state.loading = false;
    });
  },
});

const getOrderSummary = createAsyncThunk(
  'getOrderSummary',
  async (params: { range: string; query?: string }) => {
    try {
      let generatedQuery;
      const { range, query } = params;

      generatedQuery = genQuery({ range, query });

      const { data } = await dashboardApi.getOrderSummary({
        query: generatedQuery,
      });
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getUserSummary = createAsyncThunk(
  'getUserSummary',
  async (params: { range: string }) => {
    try {
      const { range } = params;
      const query = genQuery({ range });
      const { data } = await dashboardApi.getUserSummary(query!);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getCustomerSummary = createAsyncThunk(
  'getCustomerSummary',
  async (params: { range: string }) => {
    try {
      const { range } = params;
      const query = genQuery({ range });
      const { data } = await dashboardApi.getCustomerSummary(query!);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getFilteredOrderSummary = createAsyncThunk(
  'getFilteredOrderSummary',
  async (params: {
    range: string | { startDate: Date; endDate: Date };
    limit?: number;
  }) => {
    try {
      const { range, limit } = params;
      const query = genQuery({ range, displayCount: limit });
      const { data } = await dashboardApi.getFilteredOrderSummary(query!);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getRevenueSummary = createAsyncThunk(
  'getRevenueSummary',
  async (params: { range: string | { startDate: Date; endDate: Date } }) => {
    try {
      const { range } = params;

      const query = genQuery({ range });
      const { data } = await dashboardApi.getRevenueSummary(query!);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getTopKitchens = createAsyncThunk(
  'getTopKitchens',
  async (params: {
    range: string | { startDate: Date; endDate: Date };
    limit?: number;
  }) => {
    try {
      const { range, limit } = params;
      const query = genQuery({ range, displayCount: limit });
      const { data } = await dashboardApi.getTopKitchens(query!);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getTopLocations = createAsyncThunk(
  'getTopLocations',
  async (params: {
    range: string | { startDate: Date; endDate: Date };
    limit?: number;
  }) => {
    try {
      const { range, limit } = params;
      const query = genQuery({ range, displayCount: limit });
      const { data } = await dashboardApi.getTopLocations(query!);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getTopMeals = createAsyncThunk(
  'getTopMeals',
  async (params: {
    range: string | { startDate: Date; endDate: Date };
    limit?: number;
    query?: string;
  }) => {
    try {
      const { range, limit, query } = params;
      const generatedQuery = genQuery({ range, displayCount: limit, query });
      const { data } = await dashboardApi.getTopMeals({
        query: generatedQuery,
      });
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getTopBrands = createAsyncThunk(
  'getTopBrands',
  async (params: {
    range: string | { startDate: Date; endDate: Date };
    limit?: number;
  }) => {
    try {
      const { range, limit } = params;
      const query = genQuery({ range, displayCount: limit });
      const { data } = await dashboardApi.getTopBrands(query!);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getTopCustomers = createAsyncThunk(
  'getTopCustomers',
  async (params: {
    range: string | { startDate: Date; endDate: Date };
    limit?: number;
    query?: string;
  }) => {
    try {
      const { range, limit, query } = params;
      const generatedQuery = genQuery({ range, displayCount: limit, query });
      const { data } = await dashboardApi.getTopCustomers({
        query: generatedQuery,
      });
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getTopUsers = createAsyncThunk(
  'getTopUsers',
  async (params: {
    range: string | { startDate: Date; endDate: Date };
    limit?: number;
    query?: string;
  }) => {
    try {
      const { range, limit, query } = params;

      const generatedQuery = genQuery({ range, displayCount: limit, query });
      const { data } = await dashboardApi.getTopUsers({
        query: generatedQuery,
      });
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const dashboardSelector = (state: RootState) => state.dashboard;
export {
  getOrderSummary,
  getUserSummary,
  getCustomerSummary,
  getFilteredOrderSummary,
  getRevenueSummary,
  getTopKitchens,
  getTopLocations,
  getTopMeals,
  getTopBrands,
  getTopCustomers,
  getTopUsers,
};
export default dashboardSlice.reducer;
