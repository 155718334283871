import { FC, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks';
import { getMeal } from '../../../store/slices';
import { IBrandMeal, IMeal } from '../../../types';
import { NoData, Table } from '../shared';
import { Cell } from 'react-table';

interface IMealsTable {
  meals: IMeal[] | IBrandMeal[];
  loading: boolean;
  column: {
    Header: string;
    accessor?: string;
    Cell?: ({ row }: Cell) => any;
  }[];
}

export const MealsTable: FC<IMealsTable> = ({ meals, loading, column }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const mealPage = (slug: string) => {
    slug && navigate(`/dashboard/meal/${slug}`);
  };

  const editMeal = (slug?: string, e?: SyntheticEvent<HTMLButtonElement>) => {
    e && e.stopPropagation();
    if (slug) {
      dispatch(getMeal(slug));
      return navigate(`/dashboard/meals/edit/${slug}`);
    }
  };

  const createMeal = (e?: SyntheticEvent<HTMLButtonElement>) => {
    e && e.stopPropagation();
    return navigate(`/dashboard/meals/create`);
  };

  return (
    <>
      {meals.length > 0 ? (
        <>
          <Table
            columns={column}
            data={meals}
            editMethod={editMeal}
            tableFor={'meals'}
            openPage={mealPage}
          />
        </>
      ) : (
        !loading && (
          <NoData
            title="No saved meal"
            description="You haven’t added any meal. Start adding meals by clicking the button below."
            buttonText="Add New Meal"
            onclick={createMeal}
          />
        )
      )}
    </>
  );
};
