import { IUsersApi } from '../types';
import { instance, v2Instance } from './instance';

export const usersApi: IUsersApi = {
  getUser: async (id) => await instance.get(`/admin/user/${id}`),

  getAllUsersUnpaginated: async () => await instance.get(`/admin/user/all/`),

  getAllUsers: async (page, query) =>
    await v2Instance.get(
      `/admin/user/all/?page=${page || 1}${query ? '&' + query : ''}`
    ),

  searchUsers: async (query, page) =>
    await instance.get(`admin/user/search?query=${query}&page=${page || 1}`),

  createUser: async (data) => await instance.post(`/admin/user/`, data),

  updateUser: async (data, id) => await instance.put(`/admin/user/${id}`, data),

  getUserOrders: async (id) => await instance.get(`/admin/order/user/${id}`),

  getAllCustomers: async ({ page }) =>
    await v2Instance.get(`/admin/customer/all?page=${page || 1}`),

  getAllCustomersUnpaginated: async () =>
    await instance.get(`/admin/customer/all/`),

  getCustomer: async (id) => await instance.get(`/admin/customer/${id}`),

  createCustomer: async (data) => await instance.post(`/admin/customer/`, data),

  updateCustomer: async (data, id) =>
    await instance.put(`/admin/customer/${id}`, data),

  getCustomerOrders: async (id) =>
    await instance.get(`/admin/customer/${id}/orders`),

  sendNotification: async ({ type, payload, sendToAll }) =>
    await instance.post(
      `/notification/${type}${sendToAll ? '/all' : ''}`,
      payload
    ),

  createAdmin: async (payload) =>
    await instance.post(`/admin/create/`, payload),

  removeAdmin: async (username) =>
    await instance.post(`/admin/remove/${username}/`),

  subscribeToNotifications: async (platformId) =>
    await instance.post('/notification/subscribe', { platformId }),

  createWallet: async (userId) =>
    await instance.post(`/admin/user/wallet/create/${userId}`),

  getUserWalletTransactions: async (walletId, page) =>
    await instance.get(
      `/admin/wallet/transactions/${walletId}?page=${page || 1}`
    ),

  exportUserCsv: async (query) => await instance.get(`/admin/user/csv${query}`),
};
