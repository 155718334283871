import { IVariantApi } from "../types";
import { instance } from "./instance";

export const variantApi: IVariantApi = {
  getAllVariants: async () => await instance.get("/variant/all/"),
  getVariant: async (slug) => await instance.get(`/variant/${slug}/`),
  createVariant: async (payload) =>
    await instance.post("/admin/variant/create/", payload),
  updateVariant: async (payload, id) =>
    await instance.put(`/admin/variant/${id}`, payload),
};
