import { IBusinessHours } from '../types';

export const returnRiderStatus = (business: IBusinessHours) => {
  if (business.openState === 'OPEN') {
    return 'ONLINE';
  }

  if (business.openState === 'CLOSED') {
    return 'OFFLINE';
  }

  if (
    business.openState === 'HOURS' &&
    business.hours &&
    business.hours.length
  ) {
    const day = new Date().getDay();
    const dayIndex = day === 0 ? 6 : day - 1;

    if (business.hours[dayIndex].isOpen) {
      const minutes = new Date().getMinutes().toString().padStart(2, '0');
      const hours = new Date().getMinutes().toString().padStart(2, '0');

      const time = `${hours}:${minutes}`;

      if (
        time >= business.hours![dayIndex]!.openTime! &&
        time < business.hours![dayIndex]!.closeTime!
      ) {
        return 'ONLINE';
      }
    } else {
      return 'OFFLINE';
    }
  }

  return 'OFFLINE';
};
