export const ownerVariantsColumns = [
  {
    Header: 'Variant',
    accessor: 'name',
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Inactive',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Is Available?',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

export const variantsColumns = [
  {
    Header: 'Variant',
    accessor: 'name',
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Is Available?',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];
