import { IDashboardApi } from '../types/dashboard.types';
import { instance } from './instance';

export const dashboardApi: IDashboardApi = {
  getOrderSummary: async ({ query }) =>
    await instance.get(`/admin/order/analytics/summary?${query ? query : ''}`),
  getUserSummary: async (range) =>
    await instance.get(`/admin/order/analytics/user/summary?${range}`),
  getCustomerSummary: async (range) =>
    await instance.get(`/admin/order/analytics/customer/summary?${range}`),
  getFilteredOrderSummary: async (range) =>
    await instance.get(`/admin/order/analytics/summary?${range}`),
  getRevenueSummary: async (range) =>
    await instance.get(`/admin/order/analytics/revenue/summary?${range}`),
  getTopKitchens: async (range) =>
    await instance.get(`/admin/order/analytics/top/kitchens?${range}`),
  getTopLocations: async (range) =>
    await instance.get(`/admin/order/analytics/top/locations?${range}`),
  getTopMeals: async ({ query }) =>
    await instance.get(
      `/admin/order/analytics/top/meals?${query ? query : ''}`
    ),
  getTopBrands: async (range) =>
    await instance.get(`/admin/order/analytics/top/brands?${range}`),
  getTopCustomers: async ({ query }) =>
    await instance.get(
      `/admin/order/analytics/top/customers?${query ? query : ''}`
    ),
  getTopUsers: async ({ query }) =>
    await instance.get(
      `/admin/order/analytics/top/users?${query ? query : ''}`
    ),
};
