import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authSelector } from '../store/slices';
import { useAppSelector } from './redux.hooks';

export const usePermissions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAppSelector(authSelector);

  const operationsRoutes = React.useMemo(
    () => [
      '/dashboard/brand',
      '/dashboard/brands',
      '/dashboard/meal',
      '/dashboard/order',
      '/dashboard/variant',
      '/dashboard/abandoned-checkout',
      '/dashboard/abandoned-checkout',
      '/dashboard/pilot',
      '/dashboard/pilots',
    ],
    []
  );

  const hiddenMarketingRoutes = React.useMemo(() => ['/dashboard/'], []);

  const routeAdmin = React.useCallback(() => {
    if (user?.admin?.roles.includes('OPERATIONS') && user?.admin.kitchens) {
      if (
        !operationsRoutes.some((route) => location.pathname.includes(route)) &&
        location.pathname !== '/dashboard/'
      ) {
        return navigate('/dashboard/orders');
      }
    }

    if (user?.admin?.roles.includes('OPERATIONS') && !user?.admin.kitchens) {
      if (!operationsRoutes.some((route) => location.pathname.includes(route)))
        return navigate('/dashboard/orders');
    }

    if (user?.admin?.roles.includes('MARKETING')) {
      if (hiddenMarketingRoutes.some((route) => location.pathname === route))
        return navigate('/dashboard/brands');
    }
  }, [
    location.pathname,
    navigate,
    user,
    operationsRoutes,
    hiddenMarketingRoutes,
  ]);

  const isOwner = React.useMemo(
    () => user?.admin?.roles.includes('OWNER'),
    [user]
  );

  return {
    isOwner,
    routeAdmin,
  };
};
