import { Cell } from 'react-table';
import { IMeal } from '../types';

const brandsColumns: { Header: string; accessor?: string }[] = [
  {
    Header: 'Brand Name',
    accessor: 'name',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

const brandMealColumns = [
  {
    Header: 'Meal',
    accessor: 'name',
  },
  {
    Header: 'Price',
    accessor: 'basePrice',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

const groupColumns = [
  {
    Header: 'Group Name',
    accessor: 'title',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Meals',
    accessor: 'meals',
    Cell: ({ row }: Cell) =>
      row.values.meals.map((meal: IMeal, index: number) => (
        <a
          className="text-blue-600 mr-1"
          href={`/dashboard/meals/edit/${meal.slug}`}
          key={meal.id}
        >
          {meal.name}
          {index !== row.values.meals.length - 1 && ','}
        </a>
      )),
  },
  {
    Header: 'Action',
  },
];

const groupStatus = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];

const brandStatus = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Opened', value: 'OPENED' },
  { label: 'Closed', value: 'CLOSED' },
];

export {
  brandsColumns,
  brandMealColumns,
  groupColumns,
  groupStatus,
  brandStatus,
};
