import { ILocation } from "../types";

const returnLocationName = (location: number, locations: ILocation[]) => {
  let locationName;
  for (let i = 0; i < locations.length; i++) {
    if (locations[i].id === location) {
      locationName = locations[i].area;
    }
  }
  return locationName;
};

export { returnLocationName };
