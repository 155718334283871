import {
  Dispatch,
  FC,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { SecondaryRadio, PrimaryInput, PrimaryCheckbox } from '.';
import { defaultHours, mapDayOfWeek } from '../../../constants';
import { IBusinessHours, IHours, IOpenStatus } from '../../../types';
import { ToggleButton } from './buttons';

interface IBusinessComponent {
  setBusiness: Dispatch<SetStateAction<IBusinessHours>>;
  edit?: boolean;
  business?: IBusinessHours;
}

export const BusinessHours: FC<IBusinessComponent> = ({
  setBusiness,
  edit,
  business,
}) => {
  const [hours, setHours] = useState<IHours[]>(defaultHours);
  const [payload, setPayload] = useState<IBusinessHours>({
    openState: 'OPEN',
    hours: hours,
  });

  const openStatus: IOpenStatus[] = ['OPEN', 'CLOSED', 'HOURS'];

  const onGetInputValue = (
    event: SyntheticEvent<HTMLInputElement>,
    weekday: number
  ) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    let tempHours = [...hours];

    tempHours = [...tempHours].map((hour) => {
      if (hour.weekday === weekday) {
        return {
          ...hour,
          [name]: value,
        };
      }
      return hour;
    });

    setHours(tempHours);
    setBusiness({ openState: payload.openState, hours: tempHours });
  };

  const setOpenStatus = (status: IOpenStatus) => {
    let tempPayload = { ...payload };

    if (status !== 'HOURS') {
      tempPayload = { openState: status, hours: [] };
    } else {
      tempPayload = { ...payload, openState: status };
      hours.length === 0 && setHours(defaultHours);
    }

    setBusiness(tempPayload);
    setPayload(tempPayload);
  };

  const handleDay = (weekday: number, status: string) => {
    let tempHours = [...hours];

    tempHours = [...tempHours].map((hour) => {
      if (hour.weekday === weekday) {
        if (status === 'Closed')
          return {
            weekday,
            isOpen: false,
          };

        return {
          ...hour,
          isOpen: true,
        };
      }

      return hour;
    });

    setHours(tempHours);
    setBusiness({ openState: payload.openState, hours: tempHours });
  };

  const setWholeDay = (
    e: SyntheticEvent<HTMLInputElement>,
    weekday: number
  ) => {
    const checked = e.currentTarget.checked;

    let tempHours = [...hours];

    tempHours = [...tempHours].map((hour) => {
      if (hour.weekday === weekday) {
        if (checked)
          return {
            weekday,
            isOpen: true,
            wholeDay: true,
          };

        return {
          ...hour,
          wholeDay: false,
        };
      }
      return hour;
    });

    setHours(tempHours);
    setBusiness({ openState: payload.openState, hours: tempHours });
  };

  useEffect(() => {
    if (edit && business) {
      setPayload(business);
      setHours(business.hours!);
    }
  }, [edit, business]);

  return (
    <div className="mt-10">
      <p className="semibold text-neutral-800">Business Hours</p>
      <p className="mt-2 text-neutral-500 text-sm">
        Provide information about when they operate
      </p>

      <div className="flex items-center flex-wrap mt-4">
        {openStatus.map((status) => (
          <ToggleButton
            key={status}
            active={payload.openState === status}
            onClick={() => setOpenStatus(status)}
            text={status}
            type="button"
          />
        ))}
      </div>

      {payload.openState === 'HOURS' && (
        <div className="flex flex-wrap mt-5">
          {hours.map(({ weekday }, index) => (
            <div
              className="w-full sm:w-5/12 xl:w-4/12 flex flex-col items-center px-4 pb-4 bg-neutral-100 rounded mt-3 sm:mr-5"
              key={weekday}
            >
              <div className="flex w-full items-center justify-between">
                <div className="mt-5">
                  {mapDayOfWeek[weekday as keyof typeof mapDayOfWeek]}
                </div>
                <div className="flex items-center justify-center">
                  {['Closed', 'Open'].map((status) => (
                    <SecondaryRadio
                      key={status}
                      label={status}
                      name={`status${weekday}`}
                      containerClass="w-5/12"
                      checked={
                        (hours[index].isOpen && status === 'Open') ||
                        (!hours[index].isOpen && status === 'Closed')
                      }
                      onChange={() => handleDay(weekday, status)}
                    />
                  ))}
                </div>
              </div>
              {hours[index].isOpen && (
                <div className="flex flex-col w-full">
                  {!hours[index].wholeDay && (
                    <div className="flex items-center w-full">
                      <PrimaryInput
                        onChange={(e) => onGetInputValue(e, weekday)}
                        placeholder="Select time"
                        name="openTime"
                        label="Opening Time"
                        className="w-5/12"
                        type="time"
                        value={hours[index].openTime}
                      />
                      <div className="px-2 w-2/12">
                        <div className="w-full border-b border-neutral-300 mt-10" />
                      </div>
                      <PrimaryInput
                        onChange={(e) => onGetInputValue(e, weekday)}
                        placeholder="Select time"
                        name="closeTime"
                        label="Closing Time"
                        className="w-5/12"
                        type="time"
                        value={hours[index].closeTime}
                      />
                    </div>
                  )}
                  <PrimaryCheckbox
                    label="Open 24 Hours ?"
                    name="wholeDay"
                    checked={hours[index]?.wholeDay || false}
                    onChange={(e) => setWholeDay(e, weekday)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
