import React from 'react';
import { foodItemColumns } from '../../../../constants';
import { useFetch } from '../../../../hooks';
import { FoodItem, ResolvedResponse } from '../../../../types';
import { Loading, Paginator, PrimaryButton, Table } from '../../../components';
import { useNavigate } from 'react-router-dom';

const FoodItemsScreen = () => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);

  const { useFetchFoodItem } = useFetch();
  const { data, isLoading } = useFetchFoodItem<ResolvedResponse<FoodItem[]>>(
    undefined,
    { page }
  );

  const editItem = (
    id?: string,
    e?: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e && e.stopPropagation();

    if (id) {
      return navigate(`/dashboard/food-items/edit/${id}`);
    }
  };

  const currentPage = React.useMemo(
    () => data?.pagination?.currentPage || 1,
    [data?.pagination?.currentPage]
  );

  const handlePageClick = (data: any) => {
    setPage(data.selected + 1);
  };

  return (
    <div className="body1">
      {isLoading && <Loading />}
      <>
        <div className="header1">
          <h1 className="semibold text-xl">Food Item Management</h1>
          <div className="flex space-x-4">
            <PrimaryButton
              text="Create Food Item"
              onClick={() => navigate('/dashboard/food-items/create')}
            />
          </div>
        </div>
      </>

      <Table
        data={data?.data ?? []}
        columns={foodItemColumns}
        tableFor={'foodItems'}
        editMethod={editItem}
      />

      <Paginator
        click={handlePageClick}
        totalPages={data?.pagination?.totalPages || 1}
        selected={currentPage - 1}
        hasNext={data?.pagination?.hasNext || false}
      />
    </div>
  );
};

export default FoodItemsScreen;
