import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks';
import { updateMeal, updateVariant } from '../../../store/slices';
import { ICreateMeal, ICreateVariant } from '../../../types';
import { mealsApi } from '../../../api';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

interface IToggle {
  active: boolean;
  module?: string;
  primaryKey: string | number;
  item?: any;
}

export const Toggle: FC<IToggle> = ({ active, module, primaryKey }) => {
  const queryClient = useQueryClient();

  const dispatch = useAppDispatch();
  const [on, setOn] = useState(active);

  const handleToggle = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (module === 'meals') {
      dispatch(
        updateMeal({
          payload: {
            status: active ? 'OUT_OF_STOCK' : 'ACTIVE',
          } as ICreateMeal,
          id: primaryKey as number,
        })
      );
    }

    if (module === 'variants') {
      dispatch(
        updateVariant({
          payload: {
            status: active ? 'OUT_OF_STOCK' : 'ACTIVE',
          } as ICreateVariant,
          id: primaryKey as number,
        })
      );
    }

    if (module === 'options') {
      await mealsApi.updateOption(primaryKey.toString(), { active: !on });
      if (queryClient) {
        await queryClient.refetchQueries(['options']);
      }

      toast.success('Option updated!');
    }

    if (module === 'foodItems') {
      await mealsApi.updateFoodItem(primaryKey.toString(), {
        status: on ? 'OUT_OF_STOCK' : 'OPEN',
      });
      if (queryClient) {
        await queryClient.refetchQueries(['food-items']);
      }

      toast.success('Food item updated!');
    }

    setOn((prev) => !prev);
  };

  useEffect(() => {
    setOn(active);
  }, [active]);

  return (
    <button
      className={`h-8 w-16 px-1 flex rounded-full items-center transition duration-300 relative ${
        on ? 'bg-primary-700' : 'bg-neutral-400'
      }`}
      onClick={handleToggle}
    >
      {!on && <p className="absolute top-1.5 right-2 text-sm semibold">NO</p>}
      <div
        className={`h-5 w-5 rounded-full bg-white transition duration-300 ${
          on ? 'translate-x-9' : 'translate-x-0'
        }`}
      />
      {on && (
        <p className="absolute top-1.5 left-2 text-sm semibold text-white">
          YES
        </p>
      )}
    </button>
  );
};

export const CheckboxToggle: FC<IToggle> = ({ active, primaryKey, module }) => {
  const dispatch = useAppDispatch();
  const [on, setOn] = useState(active);

  const handleToggle = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (module === 'variants') {
      dispatch(
        updateVariant({
          payload: {
            status: active ? 'INACTIVE' : 'ACTIVE',
          } as ICreateVariant,
          id: primaryKey as number,
        })
      );
    } else {
      dispatch(
        updateMeal({
          payload: {
            status: active ? 'INACTIVE' : 'ACTIVE',
          } as ICreateMeal,
          id: primaryKey as number,
        })
      );
    }

    setOn((prev) => !prev);
  };

  useEffect(() => {
    setOn(active);
  }, [active]);

  return (
    <div>
      <input
        type="checkbox"
        onClick={handleToggle}
        checked={!on}
        className="h-5 w-5"
      />
    </div>
  );
};
