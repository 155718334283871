import { FC, SyntheticEvent } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { close } from '../../../assets/svg';
import { returnVariantLabel } from '../../../helpers';
import { useAppSelector } from '../../../hooks';
import { variantSelector } from '../../../store/slices';
import { IChoice, IVariant } from '../../../types';
import { MultiSelect, PrimaryInput } from '../shared';

export type TManageChoices = (x: {
  event?: SyntheticEvent<HTMLInputElement | HTMLSelectElement>;
  index?: number;
  action?: 'add' | 'remove';
}) => void;

interface IChoiceProps {
  setChoices: TManageChoices;
  index: number;
  choice: IChoice;
  edit?: boolean;
  onGetMultiSelectValue: (
    value:
      | MultiValue<{ value: number; label: string }>
      | SingleValue<{ value: any; label: any }>,
    index: number
  ) => void;
  choices: IChoice[];
}

export const Choice: FC<IChoiceProps> = ({
  setChoices,
  index,
  choice,
  edit,
  choices,
  onGetMultiSelectValue,
}) => {
  const { variants } = useAppSelector(variantSelector);

  return (
    <div className="flex flex-wrap">
      <PrimaryInput
        onChange={(event) => setChoices({ event, index })}
        placeholder="Enter Title"
        required
        name="title"
        label="Title"
        value={choice.title || ''}
      />
      <MultiSelect
        name="variants"
        label="Variants"
        placeholder="Select Variants"
        value={
          edit && choice.variants.length
            ? (choice.variants! as number[]).map((variant: number) => ({
                value: variant,
                label: returnVariantLabel(variant, variants),
              }))
            : undefined
        }
        onChange={(e) => onGetMultiSelectValue(e, index)}
        options={
          variants &&
          variants.map((variant: IVariant) => ({
            value: variant.id,
            label: variant.name,
          }))
        }
      />
      {choices.length > 0 && (
        <button
          className="focus:outline-none"
          type="button"
          onClick={() => setChoices({ action: 'remove', index })}
        >
          <img src={close} alt="remove" className="mt-10 h-4 w-4 ml-3" />
        </button>
      )}
    </div>
  );
};
