import axios from 'axios';
import { IKitchensApi } from '../types/kitchen.types';
import { instance } from './instance';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export const kitchensApi: IKitchensApi = {
  getAllKitchens: async () => await instance.get('/admin/kitchen/all/'),
  getKitchen: async (id) => await instance.get(`/admin/kitchen/${id}/`),
  getPlusCode: async (lat, lng) =>
    await axios.get(
      `https://plus.codes/api?address=${lat},${lng}&ekey=${apiKey}`
    ),
  createKitchen: async (payload) =>
    await instance.post('/admin/kitchen/create/', payload),
  updateKitchen: async (payload, id) =>
    await instance.put(`/admin/kitchen/${id}/`, payload),
  inviteStaff: async (kitchenId, payload) =>
    await instance.post(`/kitchen/${kitchenId}/invites`, payload),
};
