import { ITransactionsApi } from '../types';
import { instance } from './instance';

export const transactionsApi: ITransactionsApi = {
  getAllTransactions: async ({ query, page }) =>
    await instance.get(
      `/admin/transactions/?page=${page || 1}${query ? `${query}` : ''}`
    ),
  getWalletTransactions: async ({ page, query }) =>
    await instance.get(
      `/admin/wallet/transactions/?page=${page || 1}${query ? `${query}` : ''}`
    ),
  createWalletEntry: async (payload) =>
    await instance.post(`/admin/wallet/entry/`, payload),
};
