import React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import numeral from 'numeral';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

interface IChart {
  data?: number[];
  label?: string[];
}

export const BarChart = ({ label, data }: IChart) => {
  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: {
        border: { display: false },
        grid: {
          display: false,
        },
        ticks: {
          color: '#ADADAD',
        },
      },
      y: {
        border: { dash: [8, 8], color: '#D5D5D5', display: false },
        ticks: {
          color: '#ADADAD',
          callback: (value) => `₦${numeral(value).format('0,0')}`,
        },
      },
    },
  };

  const getChartData = React.useCallback((): ChartData<'bar'> => {
    return {
      labels: label,
      datasets: [
        {
          data: data || [],
          backgroundColor: '#51AD65',
          barThickness: 30,
          borderRadius: 6,
          borderSkipped: false,
        },
      ],
    };
  }, [label, data]);

  return <Bar options={options} data={getChartData()} />;
};

export const PieChart = ({ data, label }: IChart) => {
  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { align: 'end', position: 'right' },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `   ${context.formattedValue}`;
          },
        },
      },
    },
  };

  const getChartData = React.useCallback((): ChartData<'doughnut'> => {
    return {
      labels: label,
      datasets: [
        {
          data: data || [],
          backgroundColor: ['#51AD65', '#00509D', '#FDC500'],
          offset: 4,
          borderWidth: 4,
        },
      ],
    };
  }, [label, data]);

  return <Doughnut data={getChartData()} options={options} />;
};
