import React from 'react';
import numeral from 'numeral';
import { naira } from '../../../../../constants';
import { getOptionPrice } from '../../../../../helpers';
import { add, deleteIcon } from '../../../../../assets/svg';
import { FoodGroup, Option, OrderItem } from '../../../../../types';
import { PrimaryButton, SecondaryButton } from '../../../../components';
import {
  FoodGroupSectionProps,
  FoodItemQuantityProps,
  FoodItemSelectorProps,
  SelectOptionsProps,
} from './types';
import { v4 as uuid } from 'uuid';

export const FoodItemSelector = ({
  edit,
  foodItems,
  orderItemList,
  close,
  setOrderItemList,
}: FoodItemSelectorProps) => {
  const [step, setStep] = React.useState(1);
  const [orderItem, setOrderItem] = React.useState<OrderItem>({
    foodItem: '',
    quantity: 1,
    options: [],
    customisations: [],
    price: 0,
  });

  const addItem = () => {
    let list = [...orderItemList];

    if (edit) {
      list = list.filter((i) => i.token !== edit);
      list.push(orderItem);
    } else {
      list.push(orderItem);
    }

    setOrderItemList(list);
    close();
  };

  React.useEffect(() => {
    if (edit) {
      const itemToEdit = orderItemList.find((i) => i.token === edit);

      itemToEdit && setOrderItem(itemToEdit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  return (
    <div className="p-6 bg-white rounded">
      <p className="text-center bold">Create an order item</p>
      <div className="flex space-x-4 items-center justify-center my-3">
        {['Food Item', 'Options', 'Customizations', 'Quantity'].map(
          (name, index) => (
            <div
              key={name}
              className="flex flex-col items-center"
              onClick={() => setStep(index + 1)}
            >
              <div
                className={`h-4 w-4 rounded-full border border-neutral-500 ${
                  index + 1 === step ? 'bg-primary-200' : ''
                }`}
              />{' '}
              <p className="text-xs">{name}</p>
            </div>
          )
        )}

        <button
          className="px-6 py-1.5 bg-primary-700 rounded text-xs bold text-white absolute top-3 right-3"
          onClick={addItem}
        >
          Done
        </button>
      </div>

      {step === 1 && (
        <>
          <p className="text-xs text-center">Select the food item</p>

          <div className="grid grid-cols-4 gap-3 h-[400px] overflow-y-auto items-start">
            {foodItems.map((item) => (
              <button
                key={item.id}
                onClick={() =>
                  setOrderItem({
                    ...orderItem,
                    foodItem: item.id,
                    name: item.name,
                    price: item.price,
                    brand: item.brand,
                    token: uuid(),
                  })
                }
                className={`px-8 py-4 rounded border border-neutral-500 max-w-[300px] ${
                  item.id === orderItem.foodItem ? 'bg-primary-200' : ''
                }`}
              >
                <p className="w-full truncate">{item.name}</p>
                <p className="text-xs bold">{`${naira} ${numeral(
                  item.price
                ).format('0,0')}`}</p>
              </button>
            ))}
          </div>

          <div className="mt-4 flex justify-between">
            <SecondaryButton text="Cancel" onClick={close} />
            <PrimaryButton
              disabled={!orderItem.foodItem}
              text="Next (Select Options)"
              onClick={() => setStep(2)}
            />
          </div>
        </>
      )}

      {step === 2 && (
        <SelectOptions
          setStep={setStep}
          orderItem={orderItem}
          foodItems={foodItems}
          setOrderItem={setOrderItem}
        />
      )}

      {step === 3 && (
        <SelectCustomizations
          setStep={setStep}
          orderItem={orderItem}
          foodItems={foodItems}
          setOrderItem={setOrderItem}
        />
      )}

      {step === 4 && (
        <FoodItemQuantity
          setStep={setStep}
          addItem={addItem}
          orderItem={orderItem}
          setOrderItem={setOrderItem}
        />
      )}
    </div>
  );
};

const SelectOptions = ({
  foodItems,
  orderItem,
  setStep,
  setOrderItem,
}: SelectOptionsProps) => {
  const item = foodItems.find((i) => i.id === orderItem.foodItem);

  return (
    <>
      <p className="text-xs text-center">
        Select the options to be added to this food item
      </p>

      <div className="grid grid-cols-2 gap-4 mt-7 h-[400px] overflow-y-auto items-start">
        {item &&
          (item.foodGroup as FoodGroup[])?.map((group) => (
            <FoodGroupSection
              key={group.id}
              group={group}
              orderItem={orderItem}
              setOrderItem={setOrderItem}
            />
          ))}
      </div>

      <div className="mt-4 flex justify-between">
        <SecondaryButton
          disabled={!orderItem.foodItem}
          text="Previous (Select food item)"
          onClick={() => setStep(1)}
        />

        <PrimaryButton
          disabled={!orderItem.foodItem}
          text="Next (Select Customizations)"
          onClick={() => setStep(3)}
        />
      </div>
    </>
  );
};

const FoodGroupSection = ({
  group,
  orderItem,
  customizations,
  setOrderItem,
}: FoodGroupSectionProps) => {
  const section = customizations ? 'customisations' : 'options';

  const selectOption = (option: Option, group: FoodGroup) => {
    const tempOrderItem = { ...orderItem };
    let optionSelected = tempOrderItem[section].find(
      (o) => o.option === option.id
    );

    if (group.maximumSelection === 1 || group.minimumSelection === 1) {
      let optionFromGroup = tempOrderItem[section].find(
        (o) => o.group === group.id
      );

      if (optionFromGroup && optionFromGroup.option) {
        tempOrderItem[section] = tempOrderItem[section].filter(
          (o) => o.option !== optionFromGroup?.option
        );
      }

      tempOrderItem[section].push({
        option: option.id!,
        quantity: 1,
        price: getOptionPrice(option),
        name: option.name ?? '',
        group: group.id,
      });
    } else {
      if (optionSelected) {
        tempOrderItem[section] = tempOrderItem[section].filter(
          (o) => o.option !== option.id
        );
      } else {
        tempOrderItem[section].push({
          option: option.id!,
          quantity: 1,
          price: getOptionPrice(option),
          name: option.name ?? '',
          group: group.id,
        });
      }
    }

    setOrderItem(tempOrderItem);
  };

  React.useEffect(() => {
    const tempOrderItem = { ...orderItem };

    if (
      group.minimumSelection === 1 &&
      !tempOrderItem[section].find((i) => i.group === group.id) &&
      group.options.length > 0
    ) {
      tempOrderItem[section].push({
        option: group.options[0]?.id ?? '',
        quantity: 1,
        price: getOptionPrice(group.options[0]),
        name: group.options[0].name ?? '',
        group: group.id,
      });

      setOrderItem(tempOrderItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mt-8">
      <p className="text-neutral-700 bold text-center underline">
        {group.name}
      </p>

      {group.options.map((option) => (
        <div key={option.id} className="flex space-x-2 mt-2">
          <button
            className={`w-full border border-neutral-200 text-neutral-800 px-3 py-2 rounded text-center ${
              orderItem[section].find((o) => o.option === option?.id)
                ? 'bg-primary-200'
                : ''
            }`}
            onClick={() => selectOption(option, group)}
          >
            <p className="text-sm">{option.name}</p>
            <p className="text-xs">{`${naira} ${numeral(option.price).format(
              '0,0'
            )}`}</p>
          </button>
        </div>
      ))}
    </div>
  );
};

const SelectCustomizations = ({
  foodItems,
  orderItem,
  setStep,
  setOrderItem,
}: SelectOptionsProps) => {
  const item = foodItems.find((i) => i.id === orderItem.foodItem);

  return (
    <>
      <p className="text-xs text-center">
        Select customizations for this food item
      </p>

      <div className="grid grid-cols-2 gap-4 mt-7 h-[400px] overflow-y-auto items-start">
        {item &&
          (item.customisations as FoodGroup[])?.map((group) => (
            <FoodGroupSection
              key={group.id}
              group={group}
              orderItem={orderItem}
              setOrderItem={setOrderItem}
              customizations
            />
          ))}
      </div>

      <div className="mt-4 flex justify-between">
        <SecondaryButton
          text="Previous (Select options)"
          onClick={() => setStep(2)}
        />

        <PrimaryButton
          disabled={!orderItem.foodItem}
          text="Next (Select item quantity)"
          onClick={() => setStep(4)}
        />
      </div>
    </>
  );
};

const FoodItemQuantity = ({
  orderItem,
  addItem,
  setStep,
  setOrderItem,
}: FoodItemQuantityProps) => {
  return (
    <>
      <p className="text-xs text-center">How many plates of this item?</p>

      <div className="mt-7 h-[400px] items-center flex justify-center space-x-6">
        <button
          disabled={orderItem.quantity === 1}
          onClick={() =>
            setOrderItem({ ...orderItem, quantity: orderItem.quantity - 1 })
          }
        >
          <img src={deleteIcon} className="h-7" alt="add" />
        </button>

        <p className="text-3xl bold">{orderItem.quantity}</p>
        <button
          onClick={() =>
            setOrderItem({ ...orderItem, quantity: orderItem.quantity + 1 })
          }
        >
          <img src={add} className="h-7" alt="add" />
        </button>
      </div>

      <div className="mt-4 flex justify-between">
        <SecondaryButton
          text="Previous (Select customizations)"
          onClick={() => setStep(3)}
        />

        <PrimaryButton
          disabled={!orderItem.foodItem}
          text="Add food item to order"
          onClick={addItem}
        />
      </div>
    </>
  );
};
