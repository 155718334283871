import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '..';
import { categoriesApi } from '../../api';
import { ICategory, ICreateCategory } from '../../types';

interface ICategoryState {
  loading: boolean;
  categories: ICategory[];
  category: ICategory | null;
}

const initialState: ICategoryState = {
  loading: false,
  categories: [],
  category: null,
};

export const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCategory.fulfilled, (state) => {
      state.loading = false;
      toast.success('Category created successfully.');
    });
    builder.addCase(createCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCategories.fulfilled, (state, { payload }) => {
      state.categories = payload;
      state.loading = false;
    });
    builder.addCase(getAllCategories.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getCategory.pending, (state) => {
      state.loading = true;
      state.category = null;
    });
    builder.addCase(getCategory.fulfilled, (state, { payload }) => {
      state.category = null;
      state.loading = false;
    });
    builder.addCase(getCategory.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCategory.fulfilled, (state) => {
      state.loading = false;
      toast.success('Category updated successfully.');
    });
    builder.addCase(updateCategory.rejected, (state) => {
      state.loading = false;
    });
  },
});

const createCategory = createAsyncThunk(
  'createCategory',
  async (
    params: { payload: ICreateCategory; cb?: () => void },
    { dispatch }
  ) => {
    try {
      const { payload, cb } = params;
      const { data } = await categoriesApi.createCategory(payload);
      cb && cb();
      dispatch(getAllCategories());
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllCategories = createAsyncThunk('getAllCategories', async () => {
  try {
    const { data } = await categoriesApi.getAllCategories();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getCategory = createAsyncThunk('getCategory', async (param: string) => {
  try {
    const data = await categoriesApi.getCategory(param);
    return data;
  } catch (error: any) {
    throw error;
  }
});

const updateCategory = createAsyncThunk(
  'updateCategory',
  async (
    params: {
      payload: ICreateCategory;
      cb?: () => void;
      id: number;
    },
    { dispatch }
  ) => {
    try {
      const { payload, cb, id } = params;
      const { data } = await categoriesApi.updateCategory(payload, id);
      cb && cb();
      dispatch(getAllCategories());

      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const categorySelector = (state: RootState) => state.categories;
export { createCategory, getAllCategories, getCategory, updateCategory };

export default categorySlice.reducer;
