import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD8Wvf5gcVTjJSxO5WaGh5Buu4C1h9Vp7s',
  authDomain: 'chow-328715.firebaseapp.com',
  projectId: 'chow-328715',
  storageBucket: 'chow-328715.appspot.com',
  messagingSenderId: '639995347735',
  appId: '1:639995347735:web:060e4cb9fc3885a831ad52',
  measurementId: 'G-ZM615NWK92',
};

// Initialize Firebase
export const initializeFirebase = () => {
  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging(app);

  return { app, messaging };
};

export const requestPushNotificationPermission = async () => {
  try {
    const { messaging } = initializeFirebase();
    const permission = await Notification.requestPermission();

    if (permission === 'denied') {
      alert('Notifications are used to alert on new orders and payments');
    }

    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_KEY,
    });

    return { token, messaging };
  } catch (error) {
    console.error(error);
  }
};
