import { FC, SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { brandsColumns } from "../../../constants";
import { useAppDispatch } from "../../../hooks";
import { getBrand } from "../../../store/slices";
import { IBrand } from "../../../types";
import { NoData, Table } from "../shared";

interface IBrandsTable {
  brands: IBrand[];
  loading: boolean;
}

export const BrandsTable: FC<IBrandsTable> = ({ brands, loading }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const brandPage = (slug: string) => {
    navigate(`/dashboard/brand/${slug}`);
  };

  const _editBrand = (slug: string, e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (slug) {
      dispatch(getBrand(slug));
      return navigate(`/dashboard/brands/edit/${slug}`);
    }
    return navigate(`/dashboard/brands/create`);
  };

  return (
    <>
      {brands.length > 0 ? (
        <Table
          columns={brandsColumns}
          data={brands}
          editMethod={_editBrand}
          tableFor={"brands"}
          openPage={brandPage}
        />
      ) : (
        !loading && (
          <NoData
            title="No saved brand"
            description="You haven’t added any brand. Start adding brands by clicking the button below."
            buttonText="Add New Brand"
            onclick={() => navigate(`/dashboard/brands/create`)}
          />
        )
      )}
    </>
  );
};
