import React, { FC } from 'react';
import { FilterModule } from '../../../../types';
import { FilterDateRange } from './dateRange';
import { useAppDispatch } from '../../../../hooks';
import { resetFilter, setModule } from '../../../../store/slices';
import { AsyncThunk } from '@reduxjs/toolkit';
import { FilterProviders } from './providers';
import { FilterTransactionType } from './transactionType';
import { FilterTransactionAmount } from './transactionAmount';

interface IFilter {
  module: FilterModule;
  getState: AsyncThunk<any, { page?: number; cb?: () => void }, {}>;
}
export const Filter: FC<IFilter> = ({ module, getState }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => {
    if (!isOpen) dispatch(setModule(module));
    setIsOpen(!isOpen);
  };

  const handleApply = () => {
    dispatch(getState({ cb: toggle }));
  };

  const handleReset = () => {
    dispatch(resetFilter());
    dispatch(getState({}));
  };

  return (
    <>
      {isOpen && (
        <div
          className="absolute inset-0 bg-slate-400 bg-opacity-10"
          onClick={toggle}
        />
      )}
      <div className="relative mt-3 w-[70px]">
        <button className="px-4 py-2 border rounded bg-white" onClick={toggle}>
          Filter
        </button>

        {isOpen && (
          <div className="absolute top-16 bg-white rounded shadow-lg p-2">
            <div className="flex">
              <FilterDateRange />
              <FilterProviders />
              <FilterTransactionType />
              <FilterTransactionAmount />
            </div>
            <div className="mt-6 text-sm flex items-center space-x-5 justify-end">
              <button
                className="px-4 py-2 bg-primary-700 rounded-full text-white medium"
                onClick={handleApply}
              >
                Apply
              </button>
              <button
                className="px-4 py-2 bg-neutral-300 rounded-full medium"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
