import { Link } from 'react-router-dom';
import { footerArt, footerMail } from '../../../assets/svg';

export const Footer = () => {
  return (
    <div className="rounded-t-[50px] border-[3px] border-black bg-primary-red flex  justify-between items-center mt-40">
      <div className="w-6/12 order-1">
        <img src={footerArt} alt="art" />
      </div>

      <div className="py-10 pr-20 w-5/12 flex flex-col items-center order-2">
        <p className="text-3xl sm:text-6xl text-white leading-[120%] uppercase text-center passion-regular">
          contact us
        </p>
        <div className="mt-10 flex flex-col sm:flex-row items-center">
          <a href="tel:+2349021077210">
            <div className="bg-primary-cream border-[3px] border-primary-brown pt-0 pb-5 px-14 rounded-full">
              <p className="text-2xl leading-[45px] text-primary-brown passion-bold whitespace-nowrap">
                +234 902 107 7210
              </p>
            </div>
          </a>
          <div className="relative mt-2 sm:mt-0">
            <img src={footerMail} alt="footer-mail" />
            <a
              href="mailto:hello@500chow.com"
              className="w-full h-full -top-4 flex justify-center absolute items-center"
            >
              <p className="uppercase text-primary-brown text-2xl leading-[45px] whitespace-nowrap passion-regular">
                hello@500chow.com
              </p>
            </a>
          </div>
        </div>

        <div className="mt-32 flex divide-x text-white text-sm">
          <p className="px-5 sora-light whitespace-nowrap">
            © 2023, 500chow Technologies Limited
          </p>
          <Link to="/privacy-policy/">
            <p className="px-5 sora-light whitespace-nowrap">Privacy policy </p>
          </Link>
          <Link to="/terms-and-conditions/">
            <p className="px-5 sora-light whitespace-nowrap">
              Terms & conditions
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};
