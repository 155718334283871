import React from 'react';
import {
  Footer,
  Hero,
  Loading,
  Menu,
  PreFooter,
  WhyChoose,
} from '../../components';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useAppSelector } from '../../../hooks';
import { brandsSelector } from '../../../store/slices';
import { useParams } from 'react-router-dom';

const MenuPage = () => {
  const { loading, groups } = useAppSelector(brandsSelector);

  const [isLoading, setIsLoading] = React.useState(false);

  const { option } = useParams();

  const componentRef = React.useRef<HTMLDivElement>(null);

  const printPdf = React.useCallback(() => {
    setIsLoading(true);
    const input = document.getElementById('divToPrint');

    html2canvas(input!, { useCORS: true, allowTaint: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      if (option && option === 'png') {
        saveAs(imgData, 'menu');
      } else if (option && option === 'pdf') {
        const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(
          imgData,
          'PNG',
          0,
          0,
          pdfWidth,
          pdfHeight,
          undefined,
          'FAST'
        );

        pdf.save('menu');
      }

      setIsLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAs = async (image: string, title: string) => {
    var link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = image;
      link.download = title;

      //Firefox requires the link to be in the body
      document.body.appendChild(link);

      //simulate click
      link.click();

      //remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(image);
    }
  };

  React.useEffect(() => {
    if (!loading && groups.length) printPdf();
  }, [loading, groups, printPdf]);

  return (
    <>
      {isLoading && <Loading />}

      <div
        id="divToPrint"
        ref={componentRef}
        className={`min-w-[1440px] ${
          loading || isLoading ? 'h-screen overflow-hidden' : ''
        }`}
      >
        <Hero />
        <Menu />
        <WhyChoose />
        <PreFooter />
        <Footer />
      </div>
    </>
  );
};

export default MenuPage;
