import { SyntheticEvent, useEffect, useState } from 'react';

import { useAppDispatch } from '../../../../hooks';
import { createCustomer } from '../../../../store/slices';
import { ICreateCustomer } from '../../../../types';
import {
  PrimaryButton,
  PrimaryInput,
  SecondaryButton,
} from '../../../components';

const CreateCustomer = ({ onComplete }: { onComplete: () => void }) => {
  const dispatch = useAppDispatch();

  const [required, setRequired] = useState<boolean>(false);
  const [payload, setPayload] = useState<ICreateCustomer>({
    phoneNumber: '',
    fullName: '',
  });

  const onGetInputValue = (event: SyntheticEvent<any>) => {
    event.preventDefault();
    event.stopPropagation();

    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const _create = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const { fullName, email, phoneNumber } = payload as ICreateCustomer;

    let finalPayload;
    if (email?.length) {
      finalPayload = { fullName, email, phoneNumber };
    } else {
      finalPayload = { fullName, phoneNumber };
    }

    dispatch(
      createCustomer({
        payload: finalPayload as ICreateCustomer,
        cb: onComplete,
      })
    );
  };

  useEffect(() => {
    const { fullName } = payload as ICreateCustomer;
    setRequired(fullName.length > 0);
  }, [payload]);

  return (
    <div className="p-4 w-full bg-gray-100">
      <div className="mt-4">
        <form className="bg-white p-8 mt-2.5" onSubmit={_create}>
          <p className="semibold text-neutral-800">Customer</p>

          <div className="">
            <PrimaryInput
              onChange={onGetInputValue}
              value={payload.fullName}
              placeholder="Enter Full Name"
              name="fullName"
              label="Full Name"
              className="w-full"
            />

            <PrimaryInput
              onChange={onGetInputValue}
              value={payload.email}
              placeholder="Enter Email Address"
              name="email"
              label="Email Address"
              type="email"
              className="w-full"
            />
            <PrimaryInput
              onChange={onGetInputValue}
              value={payload.phoneNumber}
              placeholder="Enter Phone Number"
              name="phoneNumber"
              label="Phone Number"
              type="number"
              className="w-full"
            />
          </div>
          <div className="flex items-center space-x-4 mt-12">
            <PrimaryButton
              disabled={!required}
              text={`Save Customer`}
              type="submit"
            />
            <SecondaryButton text="Cancel" type="button" onClick={onComplete} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCustomer;
