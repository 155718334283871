import { Cell } from 'react-table';
import { FoodGroup, IVariant } from '../types';

const mealStatus = [
  { value: 'INACTIVE', option: 'Inactive' },
  { value: 'ACTIVE', option: 'Active' },
  { value: 'PREPARING', option: 'Preparing' },
  { value: 'OUT_OF_STOCK', option: 'Out of stock' },
] as { value: string; option: string }[];

const mealType = [
  { value: 'VARIABLE', option: 'Variable' },
  { value: 'FIXED', option: 'Fixed' },
] as { value: string; option: string }[];

const mealsColumns = [
  {
    Header: 'Meal',
    accessor: 'name',
  },
  {
    Header: 'Brand',
    accessor: 'brand',
    Cell: ({ row }: Cell) => {
      if (row.values.brand) {
        return row.values.brand.name;
      } else {
        return '';
      }
    },
  },
  {
    Header: 'Is Available?',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Price',
    accessor: 'basePrice',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

export const ownerMealsColumns = [
  {
    Header: 'Meal',
    accessor: 'name',
  },
  {
    Header: 'Brand',
    accessor: 'brand',
    Cell: ({ row }: Cell) => {
      if (row.values.brand) {
        return row.values.brand.name;
      } else {
        return '';
      }
    },
  },
  {
    Header: 'Is Available?',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Inactive',
  },
  {
    Header: 'Price',
    accessor: 'basePrice',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

const choicesColumns = [
  {
    Header: 'Choice Title',
    accessor: 'title',
  },
  {
    Header: 'Variants',
    accessor: 'variants',
    Cell: ({ row }: Cell) =>
      row.values.variants.map((variant: IVariant) => variant.name).join(', '),
  },
];

export const foodItemColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Is Available?',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

export const optionsColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Option price',
    accessor: 'price',
  },
  {
    Header: 'Item available?',
  },
  {
    Header: 'Minimum Selection',
    accessor: 'minimumSelection',
    Cell: ({ row }: Cell) => row.values.minimumSelection ?? 0,
  },
  {
    Header: 'Maximum Selection',
    accessor: 'maximumSelection',
    Cell: ({ row }: Cell) => row.values.maximumSelection ?? 0,
  },
  {
    Header: 'Groups',
    accessor: 'foodGroup',
    Cell: ({ row }: Cell) =>
      row.values.foodGroup.map((group: FoodGroup) => group.name).join(', '),
  },
  {
    Header: 'Action',
  },
];

export { mealStatus, mealType, mealsColumns, choicesColumns };

export const selectionCounts = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
];

export const foodItemStatus = [
  { label: 'Open', value: 'OPEN' },
  { label: 'Closed', value: 'CLOSED' },
  { label: 'Out of stock', value: 'OUT_OF_STOCK' },
];

export const foodItemType = [
  { label: 'Combo', value: 1 },
  { label: 'Non-combo', value: 0 },
];

export const foodItemServices = [
  { label: 'Delivery', value: 'DELIVERY' },
  { label: 'Pickup', value: 'PICKUP' },
  { label: 'Scheduled', value: 'SCHEDULED' },
  { label: 'Groups', value: 'GROUPS' },
  { label: 'Events', value: 'EVENTS' },
];
