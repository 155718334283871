import moment from 'moment';
import numeral from 'numeral';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks';
import { authSelector, orderSelector } from '../../../../store/slices';
import { IMeal, IVariant } from '../../../../types';

const website = process.env.REACT_APP_WEBSITE;
const customerCare1 = process.env.REACT_APP_CUSTOMER_CARE1;
const customerCare2 = process.env.REACT_APP_CUSTOMER_CARE2;

const OrderReceipt = () => {
  const navigate = useNavigate();
  const { order } = useAppSelector(orderSelector);
  const { user } = useAppSelector(authSelector);

  useEffect(() => {
    if (order && user) {
      window.print();
    } else {
      navigate(-1);
    }
  }, [order, user, navigate]);

  return (
    <div className="uppercase text-[8px] w-full">
      <div className="flex flex-col items-center text-center">
        <p className="semibold">{order?.brands[0].name}</p>
        <p>{order?.kitchens[0] && order?.kitchens[0].address?.area}</p>
        <p className="lowercase">
          {website && website.slice(8, website.length + 1)}
        </p>
        <p>
          {customerCare1}
          {customerCare2 && `, ${customerCare2}`}
        </p>
      </div>

      <div className="mt-2 flex flex-col justify-center">
        <p className="bold">Sales Invoice</p>
        {order?.kitchens[0] && (
          <div className="mt-3 flex">
            <p className="w-4/12">BRANCH</p>
            <span className="mr-5">:</span>
            {order?.kitchens[0].address?.label?.replace(/_/g, ' ')}
          </div>
        )}
        <div className="flex">
          <p className="w-4/12">CHOWER</p>
          <span className="mr-5">:</span>
          {order?.customer
            ? order.customer.fullName
            : order?.user.firstName + ' '}
        </div>
        <div className="flex">
          <p className="w-4/12">PHONE</p>
          <span className="mr-5">:</span>
          {order?.customer
            ? order.customer.phoneNumber
            : order?.user.phoneNumber + ' '}
        </div>
        <div className="flex">
          <p className="w-4/12">SOLD BY</p> <span className="mr-5">:</span>
          {user.firstName}
        </div>
        <div className="flex">
          <p className="w-4/12">INVOICE NO.</p>
          <span className="mr-5">:</span> {order?.slug}
        </div>
        <div className="flex">
          <p className="w-4/12">TRANS. DATE</p>
          <span className="mr-5">:</span> {moment(order?.createdAt).format('l')}
        </div>
        <div className="flex">
          <p className="w-4/12">POSTED ON</p> <span className="mr-5">:</span>
          {moment(order?.createdAt).format('l, h:mm:ss')}
        </div>
        <div className="flex">
          <p className="w-4/12">DELIVERY ADDRESS</p>{' '}
          <span className="mr-5">:</span>
          {order?.deliveryAddress?.address}
        </div>
      </div>

      <div className="border-t border-b border-dashed border-gray-700 py-2 mt-10">
        <div className="border-b border-dashed pb-2 border-gray-700 flex justify-between">
          <p className="w-7/12">ITEM</p>
          <p className="w-1/12 text-center">QTY</p>
          <p className="w-4/12 text-right">AMOUNT</p>
        </div>
        <div className="border-b border-dashed py-2 border-gray-700">
          {order?.cartItems?.map((item) => (
            <div key={item.id} className="flex justify-between">
              <div className="w-7/12">
                <p>{(item.meal as IMeal).name}</p>
                {item.cartItemVariants?.map((variant) => (
                  <p className="ml-2" key={variant.id}>
                    {(variant.variant as IVariant).name}
                    {variant.quantity > 1 && ` x${variant.quantity}`}
                  </p>
                ))}
              </div>
              <p className="w-1/12 text-center">{item.quantity}</p>
              <p className="w-4/12 text-right">
                {numeral(item.price).format('0,0.00')}
              </p>
            </div>
          ))}
        </div>

        <div className="flex justify-between pt-2">
          <p>total ({order?.cartItems?.length})</p>
          <p className="border-b border-dashed pb-2 border-gray-700 w-4/12 text-right">
            {numeral(order?.totalPrice).format('0,0.00')}
          </p>
        </div>

        <div className="flex justify-between pt-2">
          <p>DELIVERY FEE</p>
          <p>{numeral(order?.deliveryCharge).format('0,0.00')}</p>
        </div>
        <div className="flex justify-between pt-2">
          <p>service charge</p>
          <p className="border-b border-dashed pb-2 border-gray-700  w-4/12 text-right">
            {numeral(order?.serviceCharge).format('0,0.00')}
          </p>
        </div>

        <div className="flex justify-between pt-2">
          <p>INVOICE TOTAL</p>
          <p className="w-4/12 text-right ">
            (
            {numeral(order?.totalPrice! + order?.deliveryCharge!).format(
              '0,0.00'
            )}
            )
          </p>
        </div>
      </div>

      <p className="text-center mt-2 lowercase">made with ❤️ 500chow</p>
    </div>
  );
};

export default OrderReceipt;
