import { FC } from 'react';

interface IDetailElement {
  title: string;
  content?: string;
  onPress?: any;
}

export const DetailElement: FC<IDetailElement> = ({
  content,
  title,
  onPress,
}) => {
  return (
    <div
      onClick={() => onPress && onPress()}
      className="w-full sm:w-4/12 mt-8 pr-5"
    >
      <p className="medium text-neutral-500 text-sm">{title}</p>
      <p className="mt-2 text-sm text-neutral-900 cursor-pointer">{content}</p>
    </div>
  );
};
