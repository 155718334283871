import { Resource } from '../types';
import { formDataInstance } from './instance';

export const uploadResource = async (formData: FormData): Promise<Resource> => {
  const { data } = await formDataInstance.post(
    '/resource/upload/create',
    formData
  );
  return data.data;
};
