import numeral from 'numeral';
import { Cell } from 'react-table';
import { ICustomer, ISummary, IUser } from '../types';
import moment from 'moment';

const customerColumns = [
  {
    Header: 'Name',
    Cell: ({ row }: any) => row.original.fullName,
  },

  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Action',
  },
];

const customerOrderColumns = [
  {
    Header: 'Order Number',
    accessor: 'slug',
  },

  {
    Header: 'Date & Time',
    accessor: 'createdAt',
  },
  {
    Header: 'Method of Payment',
    accessor: 'methodOfPayment',
  },
  {
    Header: 'Total Price',
    accessor: 'totalPrice',
    Cell: ({ row }: Cell) => `₦${numeral(row.values.totalPrice).format('0,0')}`,
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

const gender = [
  { value: 'MALE', option: 'Male' },
  { value: 'FEMALE', option: 'Female' },
  { value: 'OTHER', option: 'Other' },
] as { value: string; option: string }[];

const AdminRoles = [
  { value: 'OWNER', option: 'Owner' },
  { value: 'OPERATIONS', option: 'Operations' },
  { value: 'CUSTOMER_SUCCESS', option: 'Customer Success' },
  { value: 'LOGISTICS', option: 'Logistics' },
  { value: 'MARKETING', option: 'Marketing' },
  { value: 'FINANCE', option: 'Finance' },
] as { value: string; option: string }[];

const addressLabels = [
  { value: 'OFFICE', option: 'Office' },
  { value: 'HOME', option: 'Home' },
  { value: 'OTHER', option: 'Other' },
  { value: 'WORK', option: 'Work' },
];

export const pilotStatus = [
  { value: 'REJECTED', option: 'Rejected' },
  { value: 'VERIFIED', option: 'Verified' },
  { value: 'PENDING', option: 'Pending' },
] as { value: string; option: string }[];

export const usersColumns = [
  {
    id: 'select',
    Header: (table: any) => {
      return (
        <>
          <input
            type="checkbox"
            {...table.getToggleAllRowsSelectedProps()}
            indeterminate="false"
          />
        </>
      );
    },
    Cell: ({ row }: any) => (
      <input
        type="checkbox"
        {...row.getToggleRowSelectedProps()}
        indeterminate="false"
        onClick={(e) => e.stopPropagation()}
      />
    ),
  },
  {
    Header: 'Name',
    Cell: ({ row }: any) =>
      row.original.firstName + ' ' + row.original.lastName,
  },
  {
    Header: 'Username',
    accessor: 'username',
  },

  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Action',
  },
];

export const userInfoCards = (
  isCustomer: boolean,
  customer: { customer: ICustomer; summary: ISummary } | null,
  user: { user: IUser; summary: ISummary } | null
) => [
  {
    title: 'Total Order Value',
    value: `₦${
      numeral(
        isCustomer
          ? customer?.summary.totalOrderValue
          : user?.summary.totalOrderValue
      ).format('0,0') !== 'NaN'
        ? numeral(
            isCustomer
              ? customer?.summary.totalOrderValue
              : user?.summary.totalOrderValue
          ).format('0,0')
        : '0'
    }`,
    bg: 'orange-gradient',
  },
  {
    title: 'Number of orders',
    value: isCustomer
      ? customer?.summary.numberOfOrders
      : user?.summary.numberOfOrders,
    bg: 'blue-gradient',
  },
  {
    title: 'Date of last order',
    value:
      moment(
        isCustomer
          ? customer?.summary.dateOfLastOrder
          : user?.summary.dateOfLastOrder
      )
        .format('L')
        .toLowerCase() !== 'invalid date'
        ? moment(
            isCustomer
              ? customer?.summary.dateOfLastOrder
              : user?.summary.dateOfLastOrder
          ).format('L')
        : '-',
    bg: 'purple-gradient',
  },
  {
    title: 'Wallet Balance',
    value: `₦${
      numeral(
        isCustomer
          ? customer?.summary.walletBalance
          : user?.summary.walletBalance
      ).format('0,0') !== 'NaN'
        ? numeral(
            isCustomer
              ? customer?.summary.walletBalance
              : user?.summary.walletBalance
          ).format('0,0')
        : '0'
    }`,
    bg: 'red-gradient',
  },
];

export const userDetails = (
  isCustomer: boolean,
  customer?: { customer: ICustomer; summary: ISummary } | null,
  user?: { user: IUser; summary: ISummary } | null
) => [
  {
    title: 'Customer Name',
    content: isCustomer
      ? customer?.customer.fullName
      : user?.user.firstName + ' ' + user?.user.lastName,
  },
  {
    title: 'Email Address',
    content: isCustomer ? customer?.customer.email : user?.user.email,
  },
  {
    title: 'Phone Number',
    content: isCustomer
      ? customer?.customer.phoneNumber
      : user?.user.phoneNumber,
  },
  {
    title: 'Username',
    content: user?.user.username,
    user: true,
  },
  {
    title: 'Address',
    content: user?.user.defaultAddress?.address,
    user: true,
  },
];

export {
  customerColumns,
  gender,
  AdminRoles,
  customerOrderColumns,
  addressLabels,
};
