import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { pilotsApi } from '../../api';
import {
  Bank,
  ICreatePilot,
  IOrder,
  IPagination,
  IPilot,
  PilotKyc,
  UpdatePilotKyc,
} from '../../types';
import { toast } from 'react-toastify';
import { genFilterQuery } from '../../helpers';

interface IPilotsState {
  loading: boolean;
  pilots: IPilot[];
  pilot: { user: IPilot | null; kyc: PilotKyc | null };
  pilotOrders: { pagination: IPagination | null; data: IOrder[] };
  banks: Bank[];
}

const initialState: IPilotsState = {
  loading: false,
  pilots: [],
  pilot: { user: null, kyc: null },
  pilotOrders: { pagination: null, data: [] },
  banks: [],
};

export const pilotsSlice = createSlice({
  name: 'pilots',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllPilots.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPilots.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.pilots = payload;
    });
    builder.addCase(getAllPilots.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getPilot.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPilot.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.pilot = { ...state.pilot, user: payload };
    });
    builder.addCase(getPilot.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getPilotOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPilotOrders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.pilotOrders = {
        pagination: payload.pagination,
        data: payload.data,
      };
    });
    builder.addCase(getPilotOrders.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updatePilot.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePilot.fulfilled, (state, { payload }) => {
      state.loading = false;
      toast.success('Pilot updated successfully.');
    });
    builder.addCase(updatePilot.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(listBanks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listBanks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.banks = payload;
    });
    builder.addCase(listBanks.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(unassignPilot.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(unassignPilot.fulfilled, (state, { payload }) => {
      state.loading = false;
      toast.success('Pilot has been unassigned.');
    });
    builder.addCase(unassignPilot.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getPilotKyc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPilotKyc.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.pilot = { ...state.pilot, kyc: payload };
    });
    builder.addCase(getPilotKyc.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updatePilotKyc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePilotKyc.fulfilled, (state, { payload }) => {
      state.loading = false;
      toast.success('Pilot updated');
    });
    builder.addCase(updatePilotKyc.rejected, (state) => {
      state.loading = false;
    });
  },
});

const getAllPilots = createAsyncThunk('getAllPilots', async () => {
  try {
    const { data } = await pilotsApi.getAllPilots();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getPilot = createAsyncThunk('getPilot', async (id: number) => {
  try {
    const query = genFilterQuery();

    const { data } = await pilotsApi.getPilot(id, query);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getPilotOrders = createAsyncThunk(
  'getPilotOrders',
  async (params: { id: number; page?: number }) => {
    try {
      const query = genFilterQuery();

      const { id, page } = params;

      const { data } = await pilotsApi.getPilotOrders(id, page, query);
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const updatePilot = createAsyncThunk(
  'updatePilot',
  async (params: { payload: ICreatePilot; cb?: () => void; id: number }) => {
    try {
      const { payload, cb, id } = params;
      const { data } = await pilotsApi.updatePilot(payload, id);
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const listBanks = createAsyncThunk('listBanks', async () => {
  try {
    const { data } = await pilotsApi.listBanks();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const unassignPilot = createAsyncThunk(
  'unassignPilot',
  async (params: { cb?: () => void; id: number }) => {
    try {
      const { cb, id } = params;
      const { data } = await pilotsApi.unassignPilot(id);
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getPilotKyc = createAsyncThunk('getPilotKyc', async (id: number) => {
  try {
    const { data } = await pilotsApi.getKyc(id);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const updatePilotKyc = createAsyncThunk(
  'updatePilotKyc',
  async (params: { id: number; payload: UpdatePilotKyc; cb?: () => void }) => {
    try {
      const { id, cb, payload } = params;
      const { data } = await pilotsApi.updateKyc(id, payload);
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const pilotsSelector = (state: RootState) => state.pilots;
export {
  getAllPilots,
  getPilot,
  updatePilot,
  listBanks,
  unassignPilot,
  getPilotKyc,
  updatePilotKyc,
  getPilotOrders,
};

export default pilotsSlice.reducer;
