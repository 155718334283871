import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import type { RootState } from '..';
import { kitchensApi } from '../../api';
import { ICreateKitchen, IKitchen, KitchenRole } from '../../types';

interface IKitchensState {
  loading: boolean;
  kitchens: IKitchen[];
  kitchen: IKitchen | null;
  plusCode: string | null;
}

const initialState: IKitchensState = {
  loading: false,
  kitchens: [],
  kitchen: null,
  plusCode: null,
};

export const kitchensSlice = createSlice({
  name: 'kitchen',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createKitchen.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createKitchen.fulfilled, (state) => {
      state.loading = false;
      toast.success('Kitchen created successfully.');
    });
    builder.addCase(createKitchen.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllKitchens.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllKitchens.fulfilled, (state, { payload }) => {
      state.kitchens = payload;
      state.loading = false;
    });
    builder.addCase(getAllKitchens.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getKitchen.pending, (state) => {
      state.loading = true;
      state.kitchen = null;
    });
    builder.addCase(getKitchen.fulfilled, (state, { payload }) => {
      state.kitchen = payload;
      state.loading = false;
    });
    builder.addCase(getKitchen.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateKitchen.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateKitchen.fulfilled, (state) => {
      state.loading = false;
      toast.success('Kitchen updated successfully.');
    });
    builder.addCase(updateKitchen.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(inviteStaff.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(inviteStaff.fulfilled, (state) => {
      state.loading = false;
      toast.success('Invite sent successfully.');
    });
    builder.addCase(inviteStaff.rejected, (state) => {
      state.loading = false;
    });
  },
});

const createKitchen = createAsyncThunk(
  'createKitchen',
  async (
    params: { payload: ICreateKitchen; cb?: () => void },
    { dispatch }
  ) => {
    try {
      const { payload, cb } = params;
      const { data } = await kitchensApi.createKitchen(payload);
      cb && cb();
      dispatch(getAllKitchens());
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllKitchens = createAsyncThunk('getAllKitchens', async () => {
  try {
    const { data } = await kitchensApi.getAllKitchens();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getKitchen = createAsyncThunk('getKitchen', async (param: number) => {
  try {
    const { data } = await kitchensApi.getKitchen(param);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const updateKitchen = createAsyncThunk(
  'updateKitchen',
  async (
    params: {
      payload: ICreateKitchen;
      cb?: () => void;
      id: number;
    },
    { dispatch }
  ) => {
    try {
      const { payload, cb, id } = params;
      const { data } = await kitchensApi.updateKitchen(payload, id);
      cb && cb();
      dispatch(getAllKitchens());
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const inviteStaff = createAsyncThunk(
  'inviteStaff',
  async (params: {
    kitchenId: number;
    email: string;
    roles: KitchenRole[];
  }) => {
    try {
      const { kitchenId, ...rest } = params;
      const { data } = await kitchensApi.inviteStaff(kitchenId, rest);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const kitchensSelector = (state: RootState) => state.kitchens;
export {
  createKitchen,
  getAllKitchens,
  getKitchen,
  updateKitchen,
  inviteStaff,
};

export default kitchensSlice.reducer;
