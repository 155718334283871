import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { close as closeIcon } from '../../../assets/svg';
import { orderStatus, paymentStatus } from '../../../constants';
import { useAppDispatch, useAppSelector, usePermissions } from '../../../hooks';
import {
  getAllOrders,
  getAllPilots,
  getOrder,
  orderSelector,
  pilotsSelector,
  unassignPilot,
  updateOrder,
} from '../../../store/slices';
import { IPilot, OrderStatus, Pilot } from '../../../types';
import {
  PrimaryButton,
  PrimaryCheckbox,
  PrimaryInput,
  PrimarySelect,
} from '../shared';
import { returnRiderStatus } from '../../../helpers/riders.helpers';

interface IQuickActions {
  toggle: () => void;
  activeFilter?: string;
}

export const QuickActions: FC<IQuickActions> = ({ activeFilter, toggle }) => {
  const { isOwner } = usePermissions();

  const dispatch = useAppDispatch();
  const { order } = useAppSelector(orderSelector);
  const { pilots } = useAppSelector(pilotsSelector);

  const [showOnlinePilots, setShowOnlinePilots] = useState(true);
  const [quickUpdates, setQuickUpdates] = useState<{
    status: OrderStatus;
    paidFor: boolean;
    notes?: string;
    pilot?: number | Pilot;
  }>({
    status: 'PLACED',
    paidFor: false,
    notes: '',
  });

  const onGetInputValue = (
    event: SyntheticEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    if (name === 'paidFor' && typeof value === 'string') {
      return setQuickUpdates({
        ...quickUpdates,
        [name]: value === '0' ? false : true,
      });
    }

    if (name === 'pilot') {
      return setQuickUpdates({
        ...quickUpdates,
        [name]: parseInt(value),
      });
    }

    return setQuickUpdates({ ...quickUpdates, [name]: value });
  };

  const removePilot = (e: React.SyntheticEvent) => {
    e.preventDefault();

    dispatch(
      unassignPilot({
        id: order!.id,
        cb: () => dispatch(getOrder(order!.id)),
      })
    );

    setQuickUpdates({
      ...quickUpdates,
      pilot: undefined,
    });
  };

  const afterUpdate = () => {
    toggle();
    dispatch(
      getAllOrders({
        status: activeFilter ? [activeFilter as OrderStatus] : undefined,
      })
    );
  };

  const _updateOrder = (event?: SyntheticEvent<HTMLFormElement>) => {
    if (!order) return;
    event?.preventDefault();

    let finalPayload = {
      status: quickUpdates.status,
      paidFor: quickUpdates.paidFor,
      notes: quickUpdates.notes,
      pilot: quickUpdates.pilot,
    };

    dispatch(
      updateOrder({
        payload: finalPayload,
        id: order.id,
        cb: afterUpdate,
      })
    );
  };

  useEffect(() => {
    if (order) {
      setQuickUpdates({
        status: order.status ?? 'PLACED',
        paidFor: order.paidFor ?? false,
        notes: order.notes,
        pilot: order.pilotId,
      });
    }
  }, [order]);

  useEffect(() => {
    dispatch(getAllPilots());

    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="bg-white p-6">
      <div className="flex justify-between items-center">
        <p className="bold text-2xl">Quick Actions</p>
        <button onClick={toggle}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>

      {order && (
        <div>
          <p className="text-sm">{order?.slug}</p>
          <p className="mt-2">
            <span className="semibold">Customer Name:</span>{' '}
            {order?.customer
              ? order?.customer.fullName
              : order?.user.firstName + ' ' + order?.user.lastName}
          </p>
        </div>
      )}

      <form className="mt-2" onSubmit={_updateOrder}>
        <div className="flex flex-wrap justify-between items-end">
          <PrimarySelect
            onChange={onGetInputValue}
            options={orderStatus}
            optionLabel="label"
            value={quickUpdates.status}
            optionValue="value"
            label="Order Status"
            name="status"
            placeholder="Select status"
            className="w-full"
          />
          {isOwner && (
            <PrimarySelect
              onChange={onGetInputValue}
              options={paymentStatus}
              optionLabel="name"
              value={quickUpdates?.paidFor ? 1 : 0}
              optionValue="value"
              label="Payment Status"
              name="paidFor"
              placeholder="Select Payment Status"
              className="w-full"
            />
          )}
          <PrimaryInput
            onChange={onGetInputValue}
            value={quickUpdates?.notes}
            placeholder="Enter order notes"
            name="notes"
            label="Order notes"
            className="w-full"
          />

          <div className="w-full flex items-end relative">
            <PrimaryCheckbox
              label="Show only online pilots"
              checked={showOnlinePilots}
              onChange={() => setShowOnlinePilots((prev) => !prev)}
              className="absolute -top-1 right-0"
            />
            <PrimarySelect
              value={(quickUpdates?.pilot as number) ?? undefined}
              onChange={onGetInputValue}
              options={pilots.filter((pilot) =>
                showOnlinePilots
                  ? returnRiderStatus(pilot.business!) === 'ONLINE'
                  : true
              )}
              optionLabel={(p: IPilot) =>
                `${p.user.firstName} (${p._count.orders})`
              }
              optionValue="id"
              label="Pilot"
              name="pilot"
              placeholder="Select pilot"
              className={`w-full ${order?.pilot ? 'pr-3' : ''}`}
            />

            {order?.pilot && (
              <button
                className="rounded p-1 text-xs bg-primary-600 text-white"
                onClick={removePilot}
              >
                Remove rider
              </button>
            )}
          </div>

          <div className="mt-4">
            <PrimaryButton text={`Update Order`} type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
};
