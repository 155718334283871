import React from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import {
  orderStatus,
  paymentMethods,
  paymentStatus,
} from '../../../../../constants';
import { useOrder } from '../../../../../hooks';
import {
  SelectInput,
  AddButton,
  SecondaryButton,
  GetAddress,
  PrimarySelect,
  TextInput,
  PrimaryButton,
  SearchUser,
} from '../../../../components';
import CreateCustomer from '../../customers/create';
import numeral from 'numeral';
import { OrderItemsSection } from './order-items';

const CreateNewOrder = () => {
  const {
    user,
    payload,
    customer,
    kitchens,
    required,
    foodItems,
    cartAmount,
    deliveryFee,
    orderChannel,
    orderChannels,
    addressPayload,
    orderItemList,
    setCustomer,
    processOrder,
    calculateCart,
    setOrderChannel,
    setOrderItemList,
    handleInputValue,
    setAddressPayload,
    handleKitchenChange,
    calculateServiceCharge,
  } = useOrder();

  const navigate = useNavigate();

  const [showModal, setShowModal] = React.useState(false);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="px-4 xl:px-12 py-12">
        <form className="py-4" onSubmit={processOrder}>
          <div className="xl:flex xl:items-center xl:justify-between mb-12">
            <div>
              <h1 className="medium text-lg">Create a new order</h1>
              <p className="mt-2 text-sm">
                Service Charge: ₦
                {numeral(calculateServiceCharge()).format('0,0')}
              </p>
              <p className="mt-2 text-sm">
                Delivery Charge: ₦{numeral(deliveryFee.fee).format('0,0')}
              </p>
              <p className="mt-2 text-sm">
                Total Order Amount: ₦
                {numeral(
                  deliveryFee.fee + cartAmount + calculateServiceCharge()
                ).format('0,0')}
              </p>
            </div>

            <div className="flex gap-4 mt-4 xl:mt-0">
              <PrimaryButton
                disabled={!required}
                text={`Create Order`}
                type="submit"
              />
              <SecondaryButton text="Cancel" type="button" onClick={goBack} />
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-4 items-start">
            <div className="space-y-4">
              <OrderItemsSection
                foodItems={foodItems}
                cartAmount={cartAmount}
                orderItemList={orderItemList}
                calculateCart={calculateCart}
                setOrderItemList={setOrderItemList}
              />

              <section className="section">
                <div className="flex justify-between">
                  <div>
                    <h3 className="semibold">Delivery</h3>
                    <p className="text-sm">Delivery & Address Details</p>
                  </div>
                  <p className="text-sm">
                    Delivery Cost: ₦{numeral(deliveryFee.fee).format('0,0')}
                  </p>
                </div>

                <GetAddress
                  label="Delivery Address"
                  address={addressPayload}
                  setAddress={setAddressPayload}
                  classNames="w-full"
                />
              </section>
            </div>

            <div className="space-y-4">
              <section className="section">
                <h3 className="semibold">Customer</h3>
                <p className="text-sm">Customer & User Details</p>

                <SearchUser setCustomer={setCustomer} customer={customer} />

                <AddButton
                  onClick={() => setShowModal(true)}
                  text="Create a new Customer"
                />
              </section>
              <section className="section">
                <h3 className="semibold">Order Information</h3>
                <p className="text-sm">Order status and notes</p>
                {user.admin?.roles.includes('OWNER') && (
                  <PrimarySelect
                    value={payload?.status}
                    onChange={handleInputValue}
                    options={orderStatus}
                    optionLabel="label"
                    optionValue="value"
                    label="Order Status"
                    name="status"
                    placeholder="Select status"
                    className="w-full"
                  />
                )}
                <TextInput
                  onChange={handleInputValue}
                  value={payload.notes}
                  placeholder="Enter notes"
                  name="notes"
                  label="Notes"
                />
                <SelectInput
                  name="kitchens"
                  label="Assigned Kitchens"
                  placeholder="Select kitchens"
                  options={kitchens}
                  handleChange={handleKitchenChange}
                  getOptionLabel={(kitchen) => kitchen.name}
                  getOptionValue={(kitchen) => kitchen.slug}
                />
              </section>
              <section className="section">
                <h3 className="semibold">Payment</h3>
                <p className="text-sm">Payment method & status</p>

                <PrimarySelect
                  onChange={handleInputValue}
                  options={paymentStatus}
                  optionLabel="name"
                  value={payload?.paidFor ? 1 : 0}
                  optionValue="value"
                  label="Payment Status"
                  name="paidFor"
                  placeholder="Select Payment Status"
                  className="w-full"
                />

                <PrimarySelect
                  onChange={handleInputValue}
                  options={paymentMethods}
                  optionLabel="option"
                  optionValue="value"
                  value={payload.methodOfPayment}
                  label="Payment Method"
                  name="methodOfPayment"
                  placeholder="Select Method of payment"
                  className="w-full"
                  required
                />

                <PrimarySelect
                  options={orderChannels}
                  onChange={(e) => setOrderChannel(e.currentTarget.value)}
                  optionLabel="channelName"
                  optionValue="id"
                  className="w-full"
                  placeholder="Where is this order from"
                  value={orderChannel}
                />
              </section>
            </div>
          </div>
        </form>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        ariaHideApp={false}
        className={`w-11/12 sm:w-5/12 default-modal`}
        overlayClassName="modal-overlay"
      >
        <CreateCustomer onComplete={() => setShowModal(false)} />
      </Modal>
    </>
  );
};

export default CreateNewOrder;
