import { useEffect, useMemo } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { AuthRoutes } from '../../../routes';
import { authSelector } from '../../../store/slices';

const Auth = () => {
  const navigate = useNavigate();
  const elements = useRoutes(AuthRoutes);

  const { is_authenticated } = useAppSelector(authSelector);

  useEffect(() => {
    is_authenticated && navigate('/dashboard/orders');
  }, [is_authenticated, navigate]);

  return useMemo(() => elements, [elements]);
};

export default Auth;
