import { ICouponsApi } from '../types';
import { instance } from './instance';

export const couponsApi: ICouponsApi = {
  getAllCoupons: async () => await instance.get('/admin/coupons/'),
  getCoupon: async (slug) => await instance.get(`/admin/coupons/${slug}`),
  createCoupon: async (payload) =>
    await instance.post('/admin/coupons/', payload),
  updateCoupon: async (payload, id) =>
    await instance.put(`/admin/coupons/${id}`, payload),
};
