import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '..';
import { ordersApi } from '../../api/orders.api';
import {
  CancelReason,
  DeliveryFee,
  IAddress,
  ICreateOrder,
  IOrder,
  IPagination,
  OrderChannel,
  OrderParams,
  OrdersStatuses,
} from '../../types';
import { genQuery } from '../../helpers';
import { NavigateFunction } from 'react-router-dom';
import { initialOrdersStatuses } from '../../constants';

interface IOrderState {
  loading: boolean;
  order: IOrder | null;
  orders: { pagination: IPagination | null; data: IOrder[] };
  abandonedOrders: { pagination: IPagination | null; data: IOrder[] };
  plusCode: string | null;
  deliveryFee: DeliveryFee;
  ordersStatuses: OrdersStatuses;
  cancelReasons: CancelReason[];
  orderChannels: OrderChannel[];
}

const initialState: IOrderState = {
  loading: false,
  order: null,
  orders: { data: [], pagination: null },
  abandonedOrders: { data: [], pagination: null },
  plusCode: null,
  deliveryFee: { fee: 0, distance: 0, estimatedDeliveryTime: 0 },
  ordersStatuses: initialOrdersStatuses,
  cancelReasons: [],
  orderChannels: [],
};

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOrder.fulfilled, (state) => {
      state.loading = false;
      toast.success('Order created successfully.');
    });
    builder.addCase(createOrder.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllOrders.fulfilled, (state, { payload }) => {
      state.orders = {
        data: payload.data,
        pagination: payload.pagination,
      };
      state.loading = false;
    });
    builder.addCase(getAllOrders.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getOrder.pending, (state) => {
      state.loading = true;
      state.order = null;
    });
    builder.addCase(getOrder.fulfilled, (state, { payload }) => {
      state.order = payload;
      state.loading = false;
    });
    builder.addCase(getOrder.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOrder.fulfilled, (state) => {
      state.loading = false;
      toast.success('Order updated successfully.');
    });
    builder.addCase(updateOrder.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAbandonedOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAbandonedOrders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.abandonedOrders = {
        data: payload.data,
        pagination: payload.pagination,
      };
    });
    builder.addCase(getAbandonedOrders.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(calculateDeliveryFee.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.deliveryFee = payload;
    });
    builder.addCase(calculateDeliveryFee.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createDeliveryRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDeliveryRequest.fulfilled, (state, { payload }) => {
      state.loading = false;
      toast.success('Delivery requested successfully.');
    });
    builder.addCase(createDeliveryRequest.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getOrderBySlug.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderBySlug.fulfilled, (state, { payload }) => {
      state.loading = false;
    });
    builder.addCase(getOrderBySlug.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(exportOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(exportOrder.fulfilled, (state, { payload }) => {
      state.loading = false;
      toast.success('CSV sent successfully');
    });
    builder.addCase(exportOrder.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getOrdersStatuses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrdersStatuses.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.ordersStatuses = payload;
    });
    builder.addCase(getOrdersStatuses.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getOrderCancelReasons.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderCancelReasons.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.cancelReasons = payload;
    });
    builder.addCase(getOrderCancelReasons.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getOrderChannels.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrderChannels.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.orderChannels = payload;
    });
    builder.addCase(getOrderChannels.rejected, (state) => {
      state.loading = false;
    });
  },
});

const createOrder = createAsyncThunk(
  'createOrder',
  async (params: { payload: ICreateOrder; cb?: () => void }, { dispatch }) => {
    try {
      const { payload, cb } = params;
      const { data } = await ordersApi.createOrder(payload);
      cb && cb();
      dispatch(getAllOrders({}));
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllOrders = createAsyncThunk(
  'getAllOrders',
  async (
    params: {
      page?: number;
      cb?: () => void;
      query?: string;
    } & OrderParams
  ) => {
    try {
      let generatedQuery;
      const { page, query, status, notstatus, cb } = params;

      generatedQuery = genQuery({ query, status, notstatus });

      const { data } = await ordersApi.getAllOrders({
        count: 30,
        page,
        query: generatedQuery,
      });
      cb && cb();

      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAbandonedOrders = createAsyncThunk(
  'getAbandonedOrders',
  async (params: { page?: number; cb?: () => void; query?: string }) => {
    try {
      let generatedQuery;
      const { page, query, cb } = params;

      generatedQuery = genQuery({ query });

      const { data } = await ordersApi.getAbandonedOrders({
        page,
        query: generatedQuery,
      });

      cb && cb();
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getOrder = createAsyncThunk('getOrder', async (param: number) => {
  try {
    const { data } = await ordersApi.getOrder(param);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const updateOrder = createAsyncThunk(
  'updateOrder',
  async (params: { payload: ICreateOrder; cb?: () => void; id: number }) => {
    try {
      const { payload, cb, id } = params;
      const { data } = await ordersApi.updateOrder(payload, id);
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const calculateDeliveryFee = createAsyncThunk(
  'calculateDeliveryFee',
  async (params: { address: IAddress; brands: number[] }) => {
    try {
      const { address, brands } = params;
      const { data } = await ordersApi.deliveryFee(address, brands);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const createDeliveryRequest = createAsyncThunk(
  'createDeliveryRequest',
  async (id: number) => {
    try {
      const { data } = await ordersApi.createDeliveryRequest(id);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getOrderBySlug = createAsyncThunk(
  'getOrderBySlug',
  async (params: { slug: string; navigate?: NavigateFunction }) => {
    try {
      const { slug, navigate } = params;

      const { data } = await ordersApi.getOrderBySlug(slug);
      navigate && navigate(`/dashboard/order/${data.data.id}`);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const exportOrder = createAsyncThunk(
  'exportOrder',
  async (params: {
    payload: { from: string | null; till?: string | null };
    cb?: () => void;
  }) => {
    try {
      const { payload, cb } = params;

      const query = genQuery({ dateFrom: payload });

      const { data } = await ordersApi.exportOrderCsv(query);
      cb && cb();
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getOrdersStatuses = createAsyncThunk('getOrdersStatuses', async () => {
  try {
    const { data } = await ordersApi.getOrdersStatus();

    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getOrderCancelReasons = createAsyncThunk(
  'getOrderCancelReasons',
  async () => {
    try {
      const { data } = await ordersApi.getOrderCancelReasons();

      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getOrderChannels = createAsyncThunk('getOrderChannels', async () => {
  try {
    const { data } = await ordersApi.getOrderChannels();

    return data.data;
  } catch (error: any) {
    throw error;
  }
});

export const orderSelector = (state: RootState) => state.orders;
export {
  createOrder,
  getAllOrders,
  getOrder,
  updateOrder,
  getAbandonedOrders,
  calculateDeliveryFee,
  createDeliveryRequest,
  getOrderBySlug,
  exportOrder,
  getOrdersStatuses,
  getOrderCancelReasons,
  getOrderChannels,
};

export default orderSlice.reducer;
