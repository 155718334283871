export const parseError = (error: any): string => {
  try {
    if (error.response && error.response.data && error.response.data.errors) {
      if (error.response.data.errors.length > 0) {
        return error.response.data.errors[0].message;
      }
    }

    if (error.response && error.response.data && error.response.data.message) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === 'jwt must be provided'
      ) {
        // return 'Try signing in with another method';
      }

      return error.response.data.message;
    }

    return error.message;
  } catch (err) {
    return 'Failed to establish a network connection';
  }
};

export const getErrCode = (err: any) => {
  if (err.response && err.response.status) {
    return err.response.status;
  }
};
