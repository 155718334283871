export enum WsEvents {
  JOIN_USER = 'JoinUser',
  JOIN_BACKOFFICE = 'JoinBackOffice',
  JOIN_ORDER = 'JoinOrder',
  PILOT_LOCATION = 'PilotLocation',
  DEVICE_UPDATES = 'PilotDeviceUpdate',
}

export type WsPilotLocation = {
  pilotId: number;
  orderId?: number;
  latitude: number;
  longitude: number;
};

export type WsPilotDeviceUpdates = {
  batteryLevel?: number;
  pilotId: number;
};
