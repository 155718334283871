import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  createVariant,
  updateVariant,
  variantSelector,
} from '../../../store/slices';
import { ICreateVariant } from '../../../types';
import {
  ImgInput,
  PrimaryButton,
  PrimaryInput,
  PrimarySelect,
  SecondaryButton,
} from '../shared';
import { close as closeIcon } from '../../../assets/svg';
import { mealStatus } from '../../../constants';
import { ImageUploader } from '../image-uploader';

interface IUpdateVariant {
  edit?: boolean;
  toggle: () => void;
}

export const CreateVariant: FC<IUpdateVariant> = ({ edit, toggle }) => {
  const dispatch = useAppDispatch();
  const { variant } = useAppSelector(variantSelector);

  const [required, setRequired] = useState<boolean>(false);
  const [payload, setPayload] = useState<ICreateVariant>({
    name: '',
    description: '',
    status: 'ACTIVE',
    price: 0,
    calories: 0,
    servings: 0,
    estimatedPreparationTime: 0,
  });

  const _createBrand = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!required) return;
    if (edit) {
      return dispatch(
        updateVariant({
          payload,
          id: variant?.id!,
          cb: toggle,
        })
      );
    }
    dispatch(createVariant({ payload, cb: toggle }));
  };

  const onGetInputValue = (
    event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;
    const type = event.currentTarget.type;

    setPayload({
      ...payload,
      [name]: type === 'number' ? parseInt(value) : value,
    });
  };

  useEffect(() => {
    const { name, price } = payload;
    setRequired(name.length > 0 && price > 0);
  }, [payload]);

  useEffect(() => {
    if (edit && variant !== null)
      setPayload({
        name: variant?.name,
        description: variant?.description,
        status: variant?.status,
        price: variant?.price,
        calories: variant?.calories,
        servings: variant?.servings,
        estimatedPreparationTime: variant?.estimatedPreparationTime,
        imageUrl: variant.imageUrl,
      });
  }, [variant, edit]);

  return (
    <div className="bg-white p-6">
      <div className="flex justify-between items-center">
        <p className="bold text-2xl">{`${edit ? 'Edit' : 'Add'} Variant`}</p>
        <button onClick={toggle}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>

      <form className="mt-5" onSubmit={_createBrand}>
        <div className="flex flex-wrap items-end">
          <PrimaryInput
            onChange={onGetInputValue}
            value={payload?.name}
            placeholder="Enter variant name"
            required
            name="name"
            label="What is the variant name?"
            className="w-5/12 mr-8"
          />

          <PrimaryInput
            onChange={onGetInputValue}
            value={payload?.description}
            placeholder="Description"
            name="description"
            label="Give a brief description"
            className="w-5/12"
          />
          <PrimaryInput
            onChange={onGetInputValue}
            value={payload?.price || ''}
            placeholder="Price"
            type="number"
            name="price"
            label="What is the price?"
            className="w-5/12 mr-8"
          />
          <PrimaryInput
            onChange={onGetInputValue}
            value={payload?.calories || ''}
            placeholder="Calories"
            type="number"
            name="calories"
            label="How many calories?"
            className="w-5/12"
          />
          <PrimaryInput
            onChange={onGetInputValue}
            value={payload?.servings || ''}
            placeholder="Servings"
            type="number"
            name="servings"
            label="How many servings?"
            className="w-5/12 mr-8"
          />
          <PrimaryInput
            onChange={onGetInputValue}
            value={payload?.estimatedPreparationTime || ''}
            placeholder="Estimated Preparation Time"
            type="number"
            name="estimatedPreparationTime"
            label="How long will it take to prepare? (minutes)"
            className="w-5/12"
          />
          <div className="w-full  flex flex-wrap">
            <ImageUploader
              name="imageUrl"
              getUpload={(val) => setPayload({ ...payload, imageUrl: val })}
            />
          </div>
          <ImgInput
            onChange={onGetInputValue}
            value={payload?.imageUrl || ''}
            placeholder="Enter Image URL"
            name="imageUrl"
            label="Image URL"
            containerClass="w-5/12 mr-8"
          />
          <PrimarySelect
            onChange={onGetInputValue}
            options={mealStatus}
            optionLabel="option"
            optionValue="value"
            value={payload.status}
            label="What Status of the variant?"
            name="status"
            placeholder="Select Variant Status"
            className="w-5/12"
          />
        </div>

        <div className="flex justify-end items-center space-x-4 mt-12">
          <SecondaryButton text="Cancel" onClick={toggle} />
          <PrimaryButton
            disabled={!required}
            text={`${edit ? 'Update' : 'Create'} Variant`}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
