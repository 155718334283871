/** @format */

import axios from 'axios';
import { _handleError } from '../helpers/api.helpers';

export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_PREFIX}`;
export const V2_BASE_URL = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_PREFIX_V2}`;

const baseInstance = () =>
  axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': true,
    },
  });

const v2BaseInstance = () =>
  axios.create({
    baseURL: V2_BASE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': true,
    },
  });

const formDataInstance = axios.create({
  baseURL: BASE_URL,
  timeoutErrorMessage: 'Network Timeout',
  headers: {
    Accept: '*/*',
    'Content-Type': 'multipart/form-data; ',
    'ngrok-skip-browser-warning': true,
  },
});

const instance = baseInstance();
const refreshInstance = baseInstance();
const v2Instance = v2BaseInstance();

//request interceptors
refreshInstance.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('refreshToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

v2Instance.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

formDataInstance.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

let tries = 0;

//response interceptors
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    tries++;
    _handleError(error, tries);
    throw error;
  }
);

v2Instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    tries++;
    _handleError(error, tries);
    throw error;
  }
);

refreshInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    tries++;
    _handleError(error, tries, 'refresh');
    throw error;
  }
);

export { instance, refreshInstance, v2Instance, formDataInstance };
