import React from 'react';
import { MenuCard } from '.';
import { brandsSelector, getAllGroups } from '../../../store/slices';
import { useAppDispatch, useAppSelector } from '../../../hooks';

export const Menu = () => {
  const [hide, setHide] = React.useState(true);

  const dispatch = useAppDispatch();
  const { groups } = useAppSelector(brandsSelector);

  const [meals] = React.useState<
    { imageUrl?: string; name: string; description?: string; id: string }[]
  >([]);

  React.useEffect(() => {
    dispatch(getAllGroups());
  }, [dispatch]);

  return (
    <div className="mt-32">
      <p className="passion-bold text-center text-black text-[40px] sm:text-[80px] uppercase leading-[100%]">
        Menu
      </p>

      <div>
        {groups
          .filter(
            (group) =>
              group.isActive &&
              group.meals.length > 0 &&
              group.meals.map((meal) => meal.status === 'ACTIVE').length > 0
          )
          .map((group) => (
            <div key={group.id}>
              <p className="passion-regular text-5xl text-center mt-5">
                {group.title}
              </p>
              <div className="mt-10 grid grid-cols-2 gap-4 px-32">
                {group.meals
                  .filter((meal) => meal.status === 'ACTIVE')
                  .map((meal) => (
                    <MenuCard key={meal.id} {...meal} />
                  ))}
              </div>
            </div>
          ))}
      </div>

      {meals.length > 0 && hide && (
        <div className="flex justify-center mt-14">
          <button
            className={`rounded-[10px] border-2 border-black bg-primary-brown py-2.5 px-6 text-white uppercase hover:bg-black transition-element-700`}
            onClick={() => setHide(false)}
          >
            See other menu options
          </button>
        </div>
      )}
    </div>
  );
};
