import React from 'react';
import { PrimaryInput } from '../inputs';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  filterSelector,
  updateTransactionAmount,
} from '../../../../store/slices';

export const FilterTransactionAmount = () => {
  const dispatch = useAppDispatch();
  const { module, ...filter } = useAppSelector(filterSelector);

  const onGetInputValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;

    const tempAmount = { ...filter[module].amount };

    if (name === 'minAmount') tempAmount.min = parseInt(value);
    if (name === 'maxAmount') tempAmount.max = parseInt(value);

    dispatch(updateTransactionAmount(tempAmount));
  };

  return (
    <div className="p-2 px-7">
      <h5 className="text-sm semibold">Transaction Amount</h5>
      <div>
        <PrimaryInput
          onChange={onGetInputValue}
          value={filter[module].amount.min || ''}
          name="minAmount"
          type="number"
          placeholder="Enter amount"
          className="w-full"
          label="Min Amount"
        />

        <PrimaryInput
          onChange={onGetInputValue}
          value={filter[module].amount.max || ''}
          name="maxAmount"
          type="number"
          placeholder="Enter amount"
          className="w-full"
          label="Max Amount"
        />
      </div>
    </div>
  );
};
