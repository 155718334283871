import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  authSelector,
  calculateDeliveryFee,
  createOrder,
  getAllKitchens,
  getOrderBySlug,
  getOrderChannels,
  kitchensSelector,
  orderSelector,
} from '../store/slices';
import {
  FoodItem,
  IAddress,
  ICreateOrder,
  ICustomer,
  IKitchen,
  IUserCustomer,
  OrderItem,
  ResolvedResponse,
  WsEvents,
  WsPilotDeviceUpdates,
  WsPilotLocation,
} from '../types';
import { IOnChange } from '../views/components';
import { useAppDispatch, useAppSelector } from './redux.hooks';
import { SocketContext } from '../context/socket';
import { useFetch } from './useFetch';
import { calculateItemPrice } from '../helpers';

export const useOrder = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const socket = React.useContext(SocketContext);

  const { useFetchFoodItem } = useFetch();
  const { data: foodItems, isLoading: loadingFoodItems } = useFetchFoodItem<
    ResolvedResponse<FoodItem[]>
  >(undefined, { count: 500 });

  const { kitchens } = useAppSelector(kitchensSelector);
  const { order, deliveryFee, orderChannels } = useAppSelector(orderSelector);
  const { is_authenticated, user } = useAppSelector(authSelector);

  const [orderItemList, setOrderItemList] = React.useState<OrderItem[]>([]);

  const [cartAmount, setCartAmount] = React.useState(0);
  const [required, setRequired] = useState<boolean>(false);

  const [pilotLocation, setPilotLocation] = useState<{
    latitude: number;
    longitude: number;
  }>();

  const [batteryLevel, setBatteryLevel] = useState(0);

  const [customer, setCustomer] = useState<IUserCustomer | undefined>({
    id: 0,
    fullName: '',
    username: '',
    phoneNumber: '',
  });

  const [payload, setPayload] = useState<ICreateOrder>({
    notes: '',
    methodOfPayment: 'TRANSFER',
    paidFor: false,
    status: 'PLACED',
  });

  const [addressPayload, setAddressPayload] = React.useState<IAddress>({
    area: '',
    address: '',
    latitude: 0,
    longitude: 0,
    name: '',
    label: 'HOME',
  });

  const [orderChannel, setOrderChannel] = React.useState('');

  const handleKitchenChange: IOnChange<IKitchen> = (kitchen) => {
    setPayload({
      ...payload,
      kitchens: kitchen as IKitchen[],
    });
  };

  const handleInputValue = (
    event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    if (name === 'paidFor' && typeof value === 'string') {
      return setPayload({
        ...payload,
        [name]: value === '0' ? false : true,
      });
    }

    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const calculateServiceCharge = () => {
    const vat = (deliveryFee.charges?.tax || 0) * cartAmount;
    const service = (deliveryFee.charges?.service || 0) * cartAmount;

    const charge = vat + service;
    const chargeLimit = deliveryFee.charges?.chargeLimit || 0;

    return charge > chargeLimit ? chargeLimit : charge;
  };

  const calculateCart = () => {
    let cartAmount = 0;

    // eslint-disable-next-line
    orderItemList.map((item) => {
      const itemAmount = calculateItemPrice(item);
      cartAmount += itemAmount;
    });

    if (addressPayload.address && orderItemList.length)
      dispatch(
        calculateDeliveryFee({
          address: addressPayload,
          brands: orderItemList.map((item) => item.brand?.id!),
        })
      );

    setCartAmount(cartAmount);
    return cartAmount;
  };

  const processOrder = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (!required) return;
    try {
      let finalPayload: ICreateOrder = {
        ...payload,
        channelId: !!orderChannel ? orderChannel : undefined,
        deliveryAddress: { ...addressPayload },
        orderItems: orderItemList,
        kitchens: !!payload.kitchens?.length
          ? (payload.kitchens as IKitchen[])?.map((kitchen) => kitchen.id)
          : [],
      };

      if (!!customer?.username) {
        finalPayload.userId = customer.id;
      } else {
        const { username, id, email, ...rest } = customer as IUserCustomer;
        finalPayload.customer = rest as ICustomer;
        finalPayload.customerId = id;
        if (!email) finalPayload.customer.email = undefined;
      }

      return dispatch(createOrder({ payload: finalPayload, cb: ordersPage }));
    } catch (error) {}
  };

  const ordersPage = React.useCallback(() => {
    navigate('/dashboard/orders');
  }, [navigate]);

  const _getLocation = React.useCallback(
    async (params?: { orderId?: number; pilotId?: number }) => {
      if (socket && is_authenticated) {
        if (socket.auth) {
          socket.on(WsEvents.PILOT_LOCATION, (event: WsPilotLocation) => {
            console.log(WsEvents.PILOT_LOCATION);

            if (params?.pilotId && event?.pilotId === params?.pilotId) {
              setPilotLocation({
                latitude: event.latitude,
                longitude: event.longitude,
              });
            }
          });
        }
      }
    },
    [socket, is_authenticated]
  );

  const _getBatteryLevel = React.useCallback(
    async (pilotId: number) => {
      if (socket && is_authenticated) {
        if (socket.auth) {
          socket.on(WsEvents.DEVICE_UPDATES, (event: WsPilotDeviceUpdates) => {
            console.log(WsEvents.DEVICE_UPDATES);
            console.log({ event });
            if (pilotId === event.pilotId) {
              event.batteryLevel && setBatteryLevel(event.batteryLevel);
            }
          });
        }
      }
    },
    [socket, is_authenticated]
  );

  const goToOrder = (slug: string) => {
    dispatch(getOrderBySlug({ slug, navigate }));
  };

  React.useEffect(() => {
    kitchens && kitchens.length === 0 && dispatch(getAllKitchens());
    orderChannels && orderChannels.length === 0 && dispatch(getOrderChannels());

    // eslint-disable-next-line
  }, [dispatch]);

  React.useEffect(() => {
    const { userId, customerId } = payload;
    const { name } = addressPayload;
    const { fullName } = customer as IUserCustomer;

    setRequired(
      (userId! > 0 || customerId! > 0 || fullName.length > 0) &&
        name?.length! > 0 &&
        orderItemList.length > 0
    );
  }, [addressPayload, customer, payload, orderItemList]);

  React.useEffect(() => {
    if (addressPayload.address && orderItemList.length)
      dispatch(
        calculateDeliveryFee({
          address: addressPayload,
          brands: orderItemList.map((item) => item.brand?.id!),
        })
      );

    // eslint-disable-next-line
  }, [addressPayload, dispatch]);

  return {
    user,
    order,
    payload,
    required,
    customer,
    kitchens,
    foodItems,
    cartAmount,
    deliveryFee,
    orderChannel,
    orderItemList,
    pilotLocation,
    orderChannels,
    addressPayload,
    loadingFoodItems,
    batteryLevel,
    goToOrder,
    setCustomer,
    _getLocation,
    processOrder,
    calculateCart,
    setOrderChannel,
    setOrderItemList,
    handleInputValue,
    setAddressPayload,
    handleKitchenChange,
    calculateServiceCharge,
    _getBatteryLevel,
  };
};
