import { bulk, convenient, delicious, variety } from '../assets/png';
import { instagram, whatsapp } from '../assets/svg';

export const socials = [
  { name: 'Whatsapp', icon: whatsapp, href: 'https://wa.me/2349021077210' },
  {
    name: 'Instagram',
    icon: instagram,
    href: 'https://www.instagram.com/500chow/',
  },
];

export const why500 = [
  {
    title: 'Delicious Meals',
    description: 'Indulge in nourishing meals made by our talented chefs.',
    image: delicious,
  },
  {
    title: 'Explore variety',
    description:
      "Enjoy our menu's tantalizing array of wholesome, exciting, and mouthwatering delights!.",
    image: variety,
  },
  {
    title: 'Convenient',
    description: 'No stress. Overcome the daily hassle of food preparation.',
    image: convenient,
  },
  {
    title: 'Buy In Bulk, Save Money',
    description: 'Save cost when you plan your meals and order in bulk.',
    image: bulk,
  },
];
