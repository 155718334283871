import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import type { RootState } from '..';
import { authApi } from '../../api';
import { saveSession } from '../../helpers/api.helpers';
import { ILoginApi, IUser } from '../../types';

interface IAuthState {
  loading: boolean;
  is_authenticated: boolean;
  user: IUser;
}

const initialState: IAuthState = JSON.parse(localStorage.getItem('auth')!) || {
  loading: false,
  is_authenticated: false,
  user: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      const { data } = payload;
      state.loading = false;
      state.is_authenticated = true;
      state.user = data.user;
      localStorage.setItem('auth', JSON.stringify(state));
    });

    builder.addCase(logout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logout.rejected, (state) => {
      state.loading = false;
      state.is_authenticated = false;
      state.user = {} as IUser;
      localStorage.removeItem('auth');
    });
    builder.addCase(logout.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.is_authenticated = false;
      state.user = {} as IUser;
      localStorage.removeItem('auth');
    });

    builder.addCase(refresh.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(refresh.rejected, (state) => {
      state.loading = false;
      toast.error('Session expired. Please login again.');
    });
    builder.addCase(refresh.fulfilled, (state, { payload }) => {
      state.loading = false;
      toast.success('Token Refreshed, try again.');
    });
  },
});

const login = createAsyncThunk('login', async (payload: ILoginApi) => {
  try {
    const { data } = await authApi.login(payload);
    const { accessToken, refreshToken } = data.data.tokens;

    saveSession({ accessToken, refreshToken, expiresIn: 0 });
    return data;
  } catch (error: any) {
    throw error;
  }
});

const logout = createAsyncThunk('logout', async () => {
  try {
    const { data } = await authApi.logout();
    return data;
  } catch (error: any) {
    throw error;
  }
});

const refresh = createAsyncThunk('refresh', async (_, { dispatch }) => {
  try {
    const { data } = await authApi.refresh();
    const { accessToken, refreshToken } = data.data;

    saveSession({ accessToken, refreshToken, expiresIn: 0 });
    return data;
  } catch (error: any) {
    dispatch(logout());
    throw error;
  }
});

export const authSelector = (state: RootState) => state.auth;
export { login, logout, refresh };

export default authSlice.reducer;
