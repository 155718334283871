import { useNavigate } from 'react-router-dom';
import { foodItemColumns } from '../../../../constants';
import { useFetch } from '../../../../hooks';
import { Loading, PrimaryButton, Table } from '../../../components';
import { FoodGroup, ResolvedResponse } from '../../../../types';

const FoodGroupsScreen = () => {
  const { useFetchFoodGroup } = useFetch();
  const { data, isLoading } =
    useFetchFoodGroup<ResolvedResponse<FoodGroup[]>>();
  const navigate = useNavigate();

  const editFoodGroup = (
    id?: string,
    e?: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e && e.stopPropagation();

    if (id) {
      return navigate(`/dashboard/food-groups/edit/${id}`);
    }
  };

  return (
    <div className="body1">
      {isLoading && <Loading />}
      <>
        <div className="header1">
          <h1 className="semibold text-xl">Food Groups Management</h1>
          <div className="flex space-x-4">
            <PrimaryButton
              text="Create Food Group"
              onClick={() => navigate('/dashboard/food-groups/create')}
            />
          </div>
        </div>
      </>

      <Table
        data={data?.data ?? []}
        columns={foodItemColumns}
        tableFor={'foodItem'}
        editMethod={editFoodGroup}
      />
    </div>
  );
};

export default FoodGroupsScreen;
