import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { FilterModule, IWalletTransactionsFilters } from '../../types';
import { walletTransactionsFilters } from '../../constants';

interface IFilterState {
  loading: boolean;
  walletTransactions: IWalletTransactionsFilters;
  transactions: any;
  module: FilterModule;
}

const initialState: IFilterState = {
  loading: false,
  walletTransactions: walletTransactionsFilters,
  transactions: walletTransactionsFilters,
  module: 'walletTransactions',
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setModule: (state, { payload }) => {
      state.module = payload;
    },
    updateDateRange: (state, { payload }) => {
      const { module } = state;
      const { startDate, endDate } = state[module].dateRange;
      state[module].dateRange = {
        startDate: payload?.startDate || startDate,
        endDate: payload?.endDate || endDate,
      };
    },
    updateProviders: (state, { payload }) => {
      const { module } = state;
      state[module].provider = payload;
    },
    updateTransactionType: (state, { payload }) => {
      const { module } = state;
      state[module].transactionType = payload;
    },
    updateTransactionAmount: (state, { payload }) => {
      const { module } = state;
      state[module].amount = payload;
    },
    resetFilter: (state) => {
      const { module } = state;
      state[module] = initialState[module];
    },
  },
  extraReducers: (builder) => {},
});

export const filterSelector = (state: RootState) => state.filter;
export const {
  updateDateRange,
  setModule,
  updateProviders,
  updateTransactionType,
  updateTransactionAmount,
  resetFilter,
} = filterSlice.actions;
export default filterSlice.reducer;
