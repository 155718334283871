import React from 'react';
import { PrimaryRadio } from '../inputs';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  filterSelector,
  updateTransactionType,
} from '../../../../store/slices';

export const FilterTransactionType = () => {
  const dispatch = useAppDispatch();
  const { module, ...filter } = useAppSelector(filterSelector);

  const transactionType = [
    { id: 'credit', name: 'Credit' },
    { id: 'debit', name: 'Debit' },
  ];

  const onGetInputValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const checked = e.currentTarget.checked;

    let tempType = filter[module].transactionType;

    if (checked) {
      tempType = value;
    }

    dispatch(updateTransactionType(tempType));
  };

  return (
    <div className="p-2 px-7 border-r border-neutral-200">
      <h5 className="text-sm semibold">Transaction Value</h5>
      <div>
        {transactionType.map(({ name, id }) => (
          <PrimaryRadio
            key={id}
            label={name}
            name="transaction-type"
            checked={filter[module].transactionType === id}
            onChange={onGetInputValue}
            value={id}
          />
        ))}
      </div>
    </div>
  );
};
