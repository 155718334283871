import {
  heroEmoji,
  heroEmoji2,
  heroFork,
  heroMotif,
  heroMotifMobile,
  heroSpoon,
  logo500,
} from '../../../assets/svg';

export const Hero = () => {
  return (
    <div className="bg-primary-brown rounded-b-[30px] sm:rounded-b-[60px] relative pt-16 pb-60 overflow-hidden">
      <img
        src={heroMotif}
        alt="motif"
        className="absolute bottom-0 w-full hidden lg:block"
      />
      <img
        src={heroMotifMobile}
        alt="motif"
        className="absolute bottom-0 w-full lg:hidden"
      />

      <img
        src={heroEmoji2}
        alt="hero-emoji"
        className="absolute bottom-60 right-60 hidden sm:block"
      />

      <div className="flex justify-center">
        <img src={logo500} alt="logo" className="w-[65px] sm:w-auto" />
      </div>

      <div className="group sm:w-5/12 mx-auto mt-24">
        <div className="flex flex-col items-center relative z-10">
          <img
            src={heroEmoji}
            alt="hero-emoji"
            className="absolute -top-10 sm:top-16 left-20 hover:-rotate-[20deg] transition-element-700 w-[40px]"
          />

          <p className="passion-black text-[70px] sm:text-[150px] text-center uppercase leading-[80%] text-primary-cream ">
            The <br className="sm:hidden" /> Food <br /> Bangs!
          </p>
          <p className="sora-light mt-2.5 sm:mt-16 text-white text-center text-base sm:text-2xl">
            Tasty meals delivered in minutes.
          </p>
        </div>

        <div className="flex justify-center">
          <div className="absolute -bottom-36 sm:-bottom-80 flex justify-center space-x-10 sm:space-x-52">
            <img
              src={heroSpoon}
              alt="hero-spoon"
              className="-rotate-[35deg] transition-element-700 w-[113px] sm:w-auto"
            />
            <img
              src={heroFork}
              alt="hero-fork"
              className="rotate-[35deg] transition-element-700 w-[113px] sm:w-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
