import React from 'react';
import { PrimaryRadio } from '../inputs';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { filterSelector, updateProviders } from '../../../../store/slices';

export const FilterProviders = () => {
  const dispatch = useAppDispatch();
  const { module, ...filter } = useAppSelector(filterSelector);

  const providers = [
    { id: 'PAYSTACK', name: 'Paystack' },
    { id: 'MONNIFY', name: 'Monnify' },
    { id: 'BANI', name: 'Bani' },
  ];

  const onGetInputValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const checked = e.currentTarget.checked;

    let tempProvider = filter[module].provider;

    if (checked) {
      tempProvider = value;
    }

    dispatch(updateProviders(tempProvider));
  };
  return (
    <div className="p-2 px-7 border-r border-neutral-200">
      <h5 className="text-sm semibold">Providers</h5>
      <div>
        {providers.map(({ name, id }) => (
          <PrimaryRadio
            key={id}
            label={name}
            checked={filter[module].provider === id}
            name={`provider-${id}`}
            onChange={onGetInputValue}
            value={id}
          />
        ))}
      </div>
    </div>
  );
};
