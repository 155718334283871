/** @format */

import { FC } from 'react';
import ReactPaginate from 'react-paginate';

interface IPaginator {
  click: (data: { selected: number }) => void;
  selected: number;
  hasNext: boolean;
  totalPages: number;
}

export const Paginator: FC<IPaginator> = ({
  click,
  selected,
  hasNext,
  totalPages,
}) => {
  if (totalPages >= 2)
    return (
      <ReactPaginate
        breakLabel=".."
        nextLabel=">"
        onPageChange={click}
        pageRangeDisplayed={window.innerWidth <= 768 ? 2 : 3}
        pageCount={totalPages}
        previousLabel="<"
        containerClassName="flex items-center justify-center pt-2 pb-5 lg:pb-10 text-dark-grey space-x-px"
        pageClassName="h-9 w-10 hover:bg-primary-600 hover:text-white transition ease-in-out duration-300 bg-accepted"
        previousClassName={`h-9 w-10 rounded-l flex items-center justify-center transition ease-in-out duration-300 bg-accepted ${
          selected === 0
            ? 'bg-neutral-300 text-white cursor-not-allowed'
            : 'hover:bg-primary-600 hover:text-white'
        }`}
        nextClassName={`h-9 w-10 rounded-r medium flex items-center justify-center transition ease-in-out duration-300 bg-accepted ${
          hasNext
            ? 'hover:bg-primary-600 hover:text-white'
            : 'bg-neutral-300 text-white cursor-not-allowed'
        }`}
        breakClassName="h-9 w-10 hover:bg-primary-600 hover:text-white transition ease-in-out duration-300 bg-accepted"
        pageLinkClassName="h-full w-full medium flex items-center justify-center overflow-hidden focus:outline-none"
        nextLinkClassName={`medium ${hasNext ? '' : 'cursor-not-allowed'}`}
        previousLinkClassName={`medium ${
          selected === 0 && 'cursor-not-allowed'
        }`}
        breakLinkClassName="h-full w-full flex items-center justify-center overflow-hidden focus:outline-none bg-accepted"
        activeLinkClassName="h-full w-full medium flex items-center bg-primary-600 text-white"
        forcePage={selected}
      />
    );
  return <></>;
};
