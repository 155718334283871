import React from 'react';
import { ImageUploaderProps } from './types';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import Resizer from 'react-image-file-resizer';
import { uploadResource } from '../../../api/file.api';
import { Loading } from '../shared';

export const ImageUploader = ({ name, getUpload }: ImageUploaderProps) => {
  const [fileName, setFileName] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleFileUpload = async (
    file: string | File | Blob | ProgressEvent<FileReader>
  ) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file as File);
      formData.append('title', name);
      formData.append('description', `Backoffice upload for ${name}`);

      const res = await uploadResource(formData);

      getUpload(res.url);
    } catch (error) {}
    setLoading(false);
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files[0]) {
      Resizer.imageFileResizer(
        e.target.files[0],
        800,
        800,
        'JPEG',
        50,
        0,
        handleFileUpload,
        'file'
      );

      setFileName(e.target.files[0].name);
    }
  };

  return (
    <div>
      <label
        htmlFor={name}
        className="border border-dashed border-neutral-300 p-4 cursor-pointer flex items-center m-4"
      >
        {loading ? <Loading /> : <DocumentPlusIcon className="h-6 w-6 mr-4" />}
        {fileName || `Upload ${name}`}
      </label>
      <input
        type="file"
        id={name}
        className="hidden"
        onChange={handleChange}
        accept="image/*"
      />
    </div>
  );
};
