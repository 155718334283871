import React from 'react';
import { PrimaryInput } from '../shared';
import { ICustomer, IUser, IUserCustomer } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { searchUsers, userSelector } from '../../../store/slices';
import { close } from '../../../assets/svg';

export const SearchUser = ({
  customer,
  setCustomer,
}: {
  customer?: IUserCustomer;
  setCustomer: (selectedCustomer?: IUserCustomer) => void;
}) => {
  const [query, setQuery] = React.useState('');
  const [showSearchModal, setShowSearchModal] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<
    IUser[] | ICustomer[]
  >([]);

  const dispatch = useAppDispatch();
  const { searchedUsers, searchLoading } = useAppSelector(userSelector);

  const handleCustomerChange = (selectedCustomer?: ICustomer | IUser) => {
    if (selectedCustomer) {
      setCustomer({
        ...selectedCustomer,
        fullName: (selectedCustomer as IUser).firstName
          ? (selectedCustomer as IUser).firstName +
            ' ' +
            (selectedCustomer as IUser).lastName
          : (selectedCustomer as ICustomer).fullName,
      } as IUserCustomer);
    } else {
      setCustomer({
        id: 0,
        fullName: '',
        username: '',
        phoneNumber: '',
      });
    }
    setQuery('');
  };

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (query) dispatch(searchUsers({ query }));
    }, 800);

    return () => clearTimeout(timeoutId);
  }, [query, dispatch]);

  React.useEffect(() => {
    if (query.length === 0) {
      setSearchResults([]);
    } else {
      setSearchResults(searchedUsers.data);
    }
  }, [query, searchedUsers]);

  return (
    <div>
      <div className="relative">
        <PrimaryInput
          placeholder="Start typing to see results"
          className="w-full"
          label="Users"
          value={query}
          onChange={(e) => setQuery(e.currentTarget.value)}
          onBlur={() => setShowSearchModal(false)}
          onFocus={() => setShowSearchModal(true)}
        />

        {customer && !!customer.id && (
          <div className="border border-neutral-300 rounded-md px-3 py-4 shadow-xsmall focus:outline-none absolute top-5 left-0 w-full flex justify-between items-center bg-white">
            <p>{`${customer.fullName}, ${customer.phoneNumber}`}</p>

            <button
              className="focus:outline-none"
              onClick={() => handleCustomerChange()}
            >
              <img src={close} alt="close-button" className="h-5" />
            </button>
          </div>
        )}

        <div
          className={`shadow absolute top-20 z-20 left-0 border bg-white w-full max-h-52 min-h-[50px] overflow-y-auto ${
            (showSearchModal || !!query.length) && !!!customer?.id
              ? ''
              : 'hidden'
          }`}
        >
          {searchLoading && <p className="text-center py-3">Loading..</p>}

          {!searchLoading && searchResults.length === 0 && (
            <p className="text-center py-3">No results found</p>
          )}

          {!searchLoading &&
            searchResults.map((user, index) => (
              <div
                className="w-full hover:bg-blue-200 px-4 py-3 text-sm cursor-pointer"
                onClick={() => handleCustomerChange(user)}
                key={`${user.id}/${index}`}
              >
                {`${
                  (user as ICustomer).fullName ??
                  (user as IUser).firstName + ' ' + (user as IUser).lastName
                }, ${user.phoneNumber}`}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
