import { ToastContainer } from 'react-toastify';
import Features from './views/features';
import 'react-toastify/dist/ReactToastify.css';
import { PageLoader } from './views/components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 6 * 5,
      },
    },
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <PageLoader />
        <Features />
      </QueryClientProvider>

      <ToastContainer
        toastClassName={() =>
          'relative flex p-3 text-sm min-h-10 rounded-md overflow-hidden mb-4 bg-blue-300'
        }
        position="top-right"
        autoClose={3000}
        hideProgressBar
      />
    </>
  );
}

export default App;
