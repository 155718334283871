import React, { SyntheticEvent } from 'react';
import { close as closeIcon } from '../../../assets/svg';
import { mealStatus } from '../../../constants';
import { useBranch } from '../../../hooks';
import { MealOverrides, UpdateBranch, VariantOverrides } from '../../../types';
import { PrimaryButton, PrimaryInput, PrimarySelect } from '../shared';

interface IQuickActions {
  toggle: () => void;
  data: any;
}

export const BranchQuickAction = ({ toggle, data }: IQuickActions) => {
  const { meals, variants, _updateBranch } = useBranch();

  const [payload, setPayload] = React.useState(data);

  const handleOverrideChange = (
    e: SyntheticEvent<HTMLSelectElement | HTMLInputElement>,
    override?: 'mealOverrides' | 'variantOverrides'
  ) => {
    let temp: Record<string, any> = { ...payload };

    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    if (override) {
      if (override === 'mealOverrides') {
        temp.mealOverrides[0][name === 'price' ? 'basePrice' : name] =
          name === 'price' ? parseInt(value) : (value as any);
      }

      if (override === 'variantOverrides') {
        temp.variantOverrides[0][name] =
          name === 'price' ? parseInt(value) : (value as any);
      }
    }

    setPayload(temp as UpdateBranch);
  };

  const override = React.useMemo(
    () =>
      payload.mealOverrides.length > 0 ? 'mealOverrides' : 'variantOverrides',
    [payload]
  );

  return (
    <div className="bg-white p-6">
      <div className="flex justify-between items-center">
        <p className="bold text-2xl">Quick Actions</p>
        <button onClick={toggle}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>

      {override && (
        <div>
          {(payload[override][0] as MealOverrides).mealId ? (
            <p className="text-sm">
              {
                meals.find(
                  (meal) =>
                    (payload[override][0] as MealOverrides)?.mealId === meal.id
                )?.name
              }
            </p>
          ) : (
            <p className="text-sm">
              {
                variants.find(
                  (variant) =>
                    (payload[override][0] as VariantOverrides)?.variantId ===
                    variant.id
                )?.name
              }
            </p>
          )}
        </div>
      )}

      <form className="mt-2" onSubmit={(e) => _updateBranch(e, payload)}>
        <div className="flex flex-wrap justify-between items-end">
          <PrimarySelect
            onChange={(e) => handleOverrideChange(e, override)}
            options={mealStatus}
            optionLabel="option"
            value={payload[override][0].status}
            optionValue="value"
            label="Item Status"
            name="status"
            placeholder="Select status"
            className="w-full"
          />
          <PrimaryInput
            onChange={(e) => handleOverrideChange(e, override)}
            value={
              (payload[override][0] as VariantOverrides)?.price ||
              (payload[override][0] as MealOverrides)?.basePrice
            }
            placeholder="Enter item price"
            name="price"
            label="Item price"
            type="number"
            className="w-full"
          />
          <div className="mt-4">
            <PrimaryButton text={`Update branch item`} type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
};
