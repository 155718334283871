import { DragEvent, FC, useState } from "react";
import { close as closeIcon, uploadFile } from "../../../assets/svg";
import { SecondaryButton, PrimaryButton } from "./buttons";
import { UploadFile } from "./uploadFile";

interface IUploadCustomerList {
  toggle: () => void;
}

export const UploadCustomerList: FC<IUploadCustomerList> = ({ toggle }) => {
  const [file, setFile] = useState<File | undefined>();

  const getDraggedInput = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const drop = event.dataTransfer!.files[0];

    setFile(drop);
  };

  const handleUpload = () => {};

  return (
    <div className="bg-white p-6">
      <div className="flex justify-end items-center">
        <button onClick={toggle}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>
      <div className="flex flex-col items-center">
        <img src={uploadFile} alt="upload" />
        <p className="text-neutral-900 text-center w-10/12 mx-auto leading-[19px] mt-8">
          Click the ‘download template’ link below to download an Excel
          spreadsheet. Fill the excel template and upload it.
        </p>
        <button className="underline text-007 focus:outline-none mt-4 text-sm">
          Download template
        </button>
      </div>

      <UploadFile
        onDrop={() => getDraggedInput}
        name="file"
        deleteImage={() => setFile(undefined)}
        file={setFile}
        isFile
        acceptedFiles=".csv, .xlsx"
      />

      <div className="flex justify-end items-center space-x-4 mt-12">
        <SecondaryButton text="Cancel" type="button" onClick={toggle} />
        <PrimaryButton
          disabled={!file}
          text="Upload customer list"
          onClick={handleUpload}
        />
      </div>
    </div>
  );
};
