import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RootState } from "..";
import { variantApi } from "../../api";
import { ICreateVariant, IVariant } from "../../types";

interface IVariantState {
  loading: boolean;
  variants: IVariant[];
  variant: IVariant | null;
  plusCode: string | null;
}

const initialState: IVariantState = {
  loading: false,
  variants: [],
  variant: null,
  plusCode: null,
};

export const variantSlice = createSlice({
  name: "variant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createVariant.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createVariant.fulfilled, (state) => {
      state.loading = false;
      toast.success("Variant created successfully.");
    });
    builder.addCase(createVariant.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllVariants.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllVariants.fulfilled, (state, { payload }) => {
      state.variants = payload;
      state.loading = false;
    });
    builder.addCase(getAllVariants.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getVariant.pending, (state) => {
      state.loading = true;
      state.variant = null;
    });
    builder.addCase(getVariant.fulfilled, (state, { payload }) => {
      state.variant = payload;
      state.loading = false;
    });
    builder.addCase(getVariant.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateVariant.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateVariant.fulfilled, (state, { payload }) => {
      state.loading = false;
      toast.success("Variant updated successfully.");
    });
    builder.addCase(updateVariant.rejected, (state) => {
      state.loading = false;
    });
  },
});

const createVariant = createAsyncThunk(
  "createVariant",
  async (
    params: { payload: ICreateVariant; cb?: () => void },
    { dispatch }
  ) => {
    try {
      const { payload, cb } = params;
      const { data } = await variantApi.createVariant(payload);
      cb && cb();
      dispatch(getAllVariants());
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllVariants = createAsyncThunk("getAllVariants", async () => {
  try {
    const { data } = await variantApi.getAllVariants();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getVariant = createAsyncThunk("getVariant", async (param: string) => {
  try {
    const { data } = await variantApi.getVariant(param);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const updateVariant = createAsyncThunk(
  "updateVariant",
  async (
    params: {
      payload: ICreateVariant;
      cb?: () => void;
      id: number;
    },
    { dispatch }
  ) => {
    try {
      const { payload, cb, id } = params;
      const { data } = await variantApi.updateVariant(payload, id);
      cb && cb();
      dispatch(getAllVariants());
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const variantSelector = (state: RootState) => state.variants;
export { createVariant, getAllVariants, getVariant, updateVariant };

export default variantSlice.reducer;
