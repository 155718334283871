import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RootState } from "..";
import { promotionsApi } from "../../api";
import { ICreatePromotion, IPromotion } from "../../types";

interface IPromotionsState {
  loading: boolean;
  promotions: IPromotion[];
  promotion: IPromotion | null;
}

const initialState: IPromotionsState = {
  loading: false,
  promotions: [],
  promotion: null,
};

export const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPromotion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPromotion.fulfilled, (state) => {
      state.loading = false;
      toast.success("Promotion created successfully.");
    });
    builder.addCase(createPromotion.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllPromotions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPromotions.fulfilled, (state, { payload }) => {
      state.promotions = payload;
      state.loading = false;
    });
    builder.addCase(getAllPromotions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getPromotion.pending, (state) => {
      state.loading = true;
      state.promotion = null;
    });
    builder.addCase(getPromotion.fulfilled, (state, { payload }) => {
      state.promotion = payload;
      state.loading = false;
    });
    builder.addCase(getPromotion.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updatePromotion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePromotion.fulfilled, (state) => {
      state.loading = false;
      toast.success("Promotion updated successfully.");
    });
    builder.addCase(updatePromotion.rejected, (state) => {
      state.loading = false;
    });
  },
});

const createPromotion = createAsyncThunk(
  "createPromotion",
  async (
    params: { payload: ICreatePromotion; cb?: () => void },
    { dispatch }
  ) => {
    try {
      const { payload, cb } = params;
      const { data } = await promotionsApi.createPromotion(payload);
      cb && cb();
      dispatch(getAllPromotions());
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllPromotions = createAsyncThunk("getAllPromotions", async () => {
  try {
    const { data } = await promotionsApi.getAllPromotions();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getPromotion = createAsyncThunk("getPromotion", async (param: number) => {
  try {
    const { data } = await promotionsApi.getPromotion(param);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const updatePromotion = createAsyncThunk(
  "updatePromotion",
  async (
    params: {
      payload: ICreatePromotion;
      cb?: () => void;
      id: number;
    },
    { dispatch }
  ) => {
    try {
      const { payload, cb, id } = params;
      const { data } = await promotionsApi.updatePromotion(payload, id);
      cb && cb();
      dispatch(getAllPromotions());
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const promotionSelector = (state: RootState) => state.promotions;
export { createPromotion, getAllPromotions, getPromotion, updatePromotion };

export default promotionSlice.reducer;
