export const footerPhone =
  'https://res.cloudinary.com/okekeobasi/image/upload/c_scale,f_auto,q_auto,w_400/v1692115685/500chow%20public/website/footer-phone_xa6il8.png';

export const delicious =
  'https://res.cloudinary.com/okekeobasi/image/upload/c_scale,f_auto,q_auto,w_400/v1692106877/500chow%20public/website/delicious_hid2tr.png';
export const variety =
  'https://res.cloudinary.com/okekeobasi/image/upload/c_scale,f_auto,q_auto,w_400/v1692106877/500chow%20public/website/variety_ogpbkc.png';
export const convenient =
  'https://res.cloudinary.com/okekeobasi/image/upload/c_scale,f_auto,q_auto,w_400/v1692106876/500chow%20public/website/convenient_x0eq1j.png';
export const bulk =
  'https://res.cloudinary.com/okekeobasi/image/upload/c_scale,f_auto,q_auto,w_400/v1692187416/500chow%20public/website/bulk_mohgcm.png';
