import { IBrand, IGetKitchenStaff, IKitchenRole, IMeal } from "../types";

const returnArea = (place: any) => {
  let neighborhood, locality, code;
  for (let i = 0; i < place.address_components.length; i++) {
    for (let j = 0; j < place.address_components[i].types.length; j++) {
      if (place.address_components[i].types[j] === "neighborhood") {
        neighborhood = place.address_components[i].long_name;
      } else if (place.address_components[i].types[j] === "locality") {
        locality = place.address_components[i].long_name;
      } else if (place.address_components[i].types[j] === "postal_code") {
        code = place.address_components[i].long_name;
      }
    }
  }

  return neighborhood || locality || code;
};

const returnAddressName = (place: string) => {
  let addressName;

  addressName = place.split(",")[0];
  return addressName;
};

const returnBrand = (brand: number, brands: IBrand[] | IMeal[]) => {
  let brandLabel;
  for (let i = 0; i < brands.length; i++) {
    if (brands[i].id === brand) {
      brandLabel = brands[i].name;
    }
  }
  return brandLabel;
};

const returnStaffValue = (
  role: IKitchenRole,
  staffs: IGetKitchenStaff[],
  query: string
) => {
  let staffValue;
  for (let i = 0; i < staffs.length; i++) {
    let staff = staffs[i];
    if (staff.role!.includes(role)) {
      let user = staff.user;
      return user![query as keyof typeof user];
    }
  }
  return staffValue;
};

export { returnArea, returnAddressName, returnBrand, returnStaffValue };
