import { footerPhone } from '../../../assets/png';
import {
  appstore,
  chowLogo,
  chowcentral,
  chowdeck,
  glovo,
  jumia,
  playstore,
  pocket,
  preFooterMotif,
  preFooterOverlay,
} from '../../../assets/svg';
import { socials } from '../../../constants';

export const PreFooter = () => {
  return (
    <div className="mt-40 px-3 sm:px-20">
      <div className="rounded-[20px] border-[3px] border-black relative bg-primary-cream px-10 pr-44 pt-7 sm:pt-20 overflow-hidden">
        <img
          src={preFooterMotif}
          alt="motif"
          className="absolute w-full left-0 bottom-0"
        />
        <img
          src={preFooterOverlay}
          alt="overlay"
          className="absolute w-full inset-0"
        />

        <div className="flex items-start justify-between relative z-10">
          <div className="flex flex-col items-start">
            <a
              rel="noreferrer"
              target={'_blank'}
              href="https://chowcentral.com"
              className="hidden"
            >
              <img src={chowLogo} alt="chow-logo" />
            </a>

            <p className="passion-bold text-5xl uppercase text-primary-brown">
              Order your favorite <br /> 500chow meal today!
            </p>

            <div className="hidden mt-5 relative z-20">
              <a
                rel="noreferrer"
                target={'_blank'}
                href="https://chowcentral.onelink.me/bvtE/t8dfjd86"
                className="block mr-2"
              >
                <img src={playstore} alt="playstore" className="w-[100px]" />
              </a>

              <a
                rel="noreferrer"
                target={'_blank'}
                href="https://chowcentral.onelink.me/bvtE/t8dfjd86"
                className="block"
              >
                <img src={appstore} alt="appstore" className="w-[100px]" />
              </a>
            </div>
          </div>

          <img
            src={footerPhone}
            alt="phone"
            className="w-[330px] mt-10 lg:mt-0"
          />
        </div>

        <div className="xl:flex hidden absolute bottom-14 z-10">
          <a
            rel="noreferrer"
            target={'_blank'}
            href="https://chowcentral.com"
            className="pr-6"
          >
            <img src={chowcentral} alt="chow" />
          </a>
          <a
            rel="noreferrer"
            target={'_blank'}
            href="https://chowcentral.onelink.me/bvtE/t8dfjd86"
            className="block mr-2"
          >
            <img src={playstore} alt="playstore" />
          </a>
          <a
            rel="noreferrer"
            target={'_blank'}
            href="https://chowcentral.onelink.me/bvtE/t8dfjd86"
            className="block"
          >
            <img src={appstore} alt="appstore" />
          </a>
        </div>
      </div>

      <p className="mt-16 text-center sora text-3xl">
        We are also available on
      </p>
      <div className="flex justify-center space-x-7 mt-10">
        {socials.map((social) => (
          <a
            rel="noreferrer"
            target={'_blank'}
            href={social.href}
            className="flex items-center space-x-3"
            key={social.name}
          >
            <p className="text-sm sm:text-2xl passion-bold uppercase">
              {social.name}
            </p>
            <img
              src={social.icon}
              className="w-[30px] sm:w-auto"
              alt={`${social.name}-icon`}
            />
          </a>
        ))}
      </div>

      <div className="flex items-center justify-center space-x-2 sm:space-x-7 mt-10 px-4">
        <img src={jumia} alt="icon" className="w-1/4 sm:w-auto" />
        <img src={chowdeck} alt="icon" className="w-1/4 sm:w-auto" />
        <img src={glovo} alt="icon" className="w-1/4 sm:w-auto" />
        <img src={pocket} alt="icon" className="w-1/4 sm:w-auto" />
      </div>
    </div>
  );
};
