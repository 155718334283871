import React from 'react';
import { PilotKycStatus } from '../types';
import { useAppDispatch, useAppSelector } from './redux.hooks';
import {
  getPilot,
  getPilotKyc,
  pilotsSelector,
  updatePilotKyc,
} from '../store/slices';
import { useParams } from 'react-router-dom';

export const usePilot = () => {
  const dispatch = useAppDispatch();

  const {
    pilot: { kyc, user },
  } = useAppSelector(pilotsSelector);

  let { id } = useParams();

  const [status, setStatus] = React.useState<PilotKycStatus>(
    kyc?.status ?? 'PENDING'
  );

  const handleStatusChange = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value as PilotKycStatus;

    setStatus(value);
  };

  const _updatePilotKyc = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (user) {
      dispatch(
        updatePilotKyc({
          id: user.userId,
          payload: { status },
          cb: () => refetchPilot(),
        })
      );
    }
  };

  const refetchPilot = () => {
    if (id && user) {
      dispatch(getPilot(parseInt(id)));
      dispatch(getPilotKyc(user.userId));
    }
  };

  React.useEffect(() => {
    if (id && kyc) {
      setStatus(kyc.status);
    }
  }, [id, kyc]);

  return { id, status, handleStatusChange, _updatePilotKyc };
};
