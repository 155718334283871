import { AsyncThunk } from '@reduxjs/toolkit';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from './redux.hooks';

interface IUseEdit<T> {
  name: string;
  data: T | null;
  setPayload: (x: T) => any;
  callback?: (x?: any) => any;
  getState: AsyncThunk<any, string, {}>;
}

type IParams = {
  action: 'create' | 'edit';
  slug?: string;
  id?: string;
};

export const useEdit = <T>({
  name,
  data,
  setPayload,
  callback,
  getState,
}: IUseEdit<T>) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { action, slug } = useParams<IParams>();
  const [edit, setEdit] = React.useState<boolean>(false);

  const navigateToPage = React.useCallback(() => {
    navigate(`/dashboard/${name}`);
  }, [navigate, name]);

  React.useEffect(() => {
    if (edit && data && data !== null) {
      setPayload(data);

      callback && callback();
    }
  }, [data, edit, setPayload, callback]);

  React.useEffect(() => {
    action && action !== 'create' && action !== 'edit' && navigateToPage();

    if (action === 'edit' && slug) {
      setEdit(true);
      dispatch(getState(slug));
    } else if (action === 'create' && slug) {
      navigateToPage();
    }
  }, [action, navigateToPage, slug, dispatch, getState]);

  return { edit };
};
