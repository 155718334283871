const categoriesColumns = [
  {
    Header: "Category Name",
    accessor: "name",
  },
  {
    Header: "Date Created",
    accessor: "createdAt",
  },
  {
    Header: "Action",
  },
];

export { categoriesColumns };
