import { IPromotionsApi } from "../types";
import { instance } from "./instance";

export const promotionsApi: IPromotionsApi = {
  getAllPromotions: async () => await instance.get("/promotions/all/"),
  getPromotion: async (id) => await instance.get(`/promotions/${id}/`),
  createPromotion: async (payload) =>
    await instance.post("/admin/promotions/", payload),
  updatePromotion: async (payload, id) =>
    await instance.put(`/admin/promotions/${id}`, payload),
};
