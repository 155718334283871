import { Dispatch, FC, SetStateAction, SyntheticEvent } from "react";
import Autocomplete from "react-google-autocomplete";
import { addressLabels, kitchenLabels } from "../../../constants";
import { returnAddressName, returnArea } from "../../../helpers";
import { IAddress } from "../../../types";
import { PrimaryInput, PrimarySelect } from "./inputs";

interface IGetAddress {
  label: string;
  edit?: boolean;
  setAddress: Dispatch<SetStateAction<IAddress>>;
  address?: IAddress;
  classNames?: string;
}

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export const GetAddress: FC<IGetAddress> = ({
  label,
  edit,
  setAddress,
  address,
  classNames,
}) => {
  const setAddressPayload = (
    e?: SyntheticEvent<HTMLInputElement | HTMLSelectElement> | any
  ) => {
    if (e && e.currentTarget && address) {
      const value = e.currentTarget.value;
      const name = e.currentTarget.name;

      let tempAddress: IAddress = { ...address };

      tempAddress = { ...address, [name]: value };

      setAddress(tempAddress);
    } else if (address) {
      setAddress({
        ...address,
        address: e.formatted_address,
        longitude: e.geometry.location.lng(),
        latitude: e.geometry.location.lat(),
        area: returnArea(e),
        placeId: e.place_id,
        name: returnAddressName(e.formatted_address),
      });
    }
  };

  return (
    <>
      <div className={`mt-7 flex flex-col ${classNames || "input"}`}>
        <label className="medium text-sm text-neutral-900">{label}</label>
        <Autocomplete
          apiKey={apiKey}
          className="border border-neutral-300 rounded-md px-3 py-4 shadow-xsmall focus:outline-none w-full"
          defaultValue={edit ? address?.address : undefined}
          options={{
            types: [],
            componentRestrictions: { country: "ng" },
          }}
          onPlaceSelected={(place) => {
            setAddressPayload(place);
          }}
        />
      </div>
      <PrimarySelect
        onChange={setAddressPayload}
        options={label.includes("Kitchen") ? kitchenLabels : addressLabels}
        optionLabel="option"
        optionValue="value"
        value={address?.label}
        label="How will you label this address?"
        name="label"
        placeholder="Select Address label"
        className={classNames}
      />
      <PrimaryInput
        onChange={setAddressPayload}
        placeholder="Enter address landmark"
        value={address?.landmark || ""}
        name="landmark"
        label="Landmark"
        className={classNames}
      />
    </>
  );
};
