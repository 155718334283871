import { FC, SyntheticEvent, useEffect, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import { close as closeIcon } from "../../../assets/svg";
import { groupStatus } from "../../../constants";
import { returnMealName } from "../../../helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  brandsSelector,
  createGroup,
  getAllMeals,
  mealSelector,
  updateGroup,
} from "../../../store/slices";
import { IBrandMeal, ICreateGroup, IMeal } from "../../../types";
import {
  MultiSelect,
  PrimaryButton,
  PrimaryInput,
  PrimarySelect,
  SecondaryButton,
} from "../shared";

interface ICreateGroupModal {
  edit?: boolean;
  toggle: () => void;
  brandId: number;
}

export const CreateGroup: FC<ICreateGroupModal> = ({
  edit,
  toggle,
  brandId,
}) => {
  const dispatch = useAppDispatch();
  const { group } = useAppSelector(brandsSelector);
  const { meals } = useAppSelector(mealSelector);
  const { brand } = useAppSelector(brandsSelector);

  const [required, setRequired] = useState<boolean>(false);
  const [payload, setPayload] = useState<ICreateGroup>({
    title: "",
    description: "",
    brand: brandId,
    meals: [],
    isActive: false,
    rank: 0,
  });

  const onGetInputValue = (
    event: SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    if (name === "isActive" && typeof value === "string") {
      return setPayload({
        ...payload,
        [name]: value === "0" ? false : true,
      });
    }

    setPayload({
      ...payload,
      [name]: name === "rank" ? parseInt(value) : value,
    });
  };

  const onGetMultiSelectValue = (
    value:
      | MultiValue<{ value: number; label: string | undefined }>
      | SingleValue<{ value: any; label: any }>,
    name: string
  ) => {
    if (Array.isArray(value))
      setPayload({ ...payload, [name]: value.map((item) => item.value) });
  };

  const _createGroup = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!required) return;
    if (edit) {
      return dispatch(
        updateGroup({
          payload,
          id: group!.id,
          cb: toggle,
        })
      );
    }
    dispatch(createGroup({ payload, cb: toggle }));
  };

  useEffect(() => {
    if (edit && group !== null)
      setPayload({
        title: group?.title!,
        description: group?.description,
        meals: group.meals ? group?.meals.map((meal: IMeal) => meal?.id!) : [],
        brand: group.brandId,
        isActive: group.isActive,
        rank: group.rank,
      });
  }, [group, edit]);

  useEffect(() => {
    const { title } = payload;
    setRequired(title.length > 0);
  }, [payload]);

  useEffect(() => {
    meals && meals.length < 1 && dispatch(getAllMeals());
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="bg-white p-6">
      <div className="flex justify-between items-center">
        <p className="bold text-2xl">{`${edit ? "Edit" : "Create"} Group`}</p>
        <button onClick={toggle}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>

      <form className="mt-5" onSubmit={_createGroup}>
        <div className="flex flex-wrap justify-between items-end">
          <PrimaryInput
            onChange={onGetInputValue}
            value={payload?.title}
            placeholder="Enter group name"
            required
            name="title"
            label="What is the name of the group?"
            className="w-full"
          />

          <MultiSelect
            className="w-full"
            name="meals"
            label="Meals"
            placeholder="Select Meals"
            value={
              payload.meals?.length
                ? (payload.meals as number[]).map((meal: number) => ({
                    value: meal,
                    label: returnMealName(meal, meals),
                  }))
                : undefined
            }
            onChange={(e) => onGetMultiSelectValue(e, "meals")}
            options={brand!.meals?.map((meal: IBrandMeal) => ({
              value: meal.id,
              label: meal.name,
            }))}
          />
          <PrimaryInput
            onChange={onGetInputValue}
            value={payload?.description || ""}
            placeholder="Enter group description"
            name="description"
            label="Describe the group"
            className="w-full"
          />
          <PrimaryInput
            onChange={onGetInputValue}
            value={payload.rank || 0}
            placeholder="Enter group rank"
            name="rank"
            label="Group Rank"
            className="w-5/12 sm:w-full"
            type="number"
          />
          <PrimarySelect
            onChange={onGetInputValue}
            options={groupStatus}
            optionLabel="label"
            optionValue="value"
            value={payload?.isActive ? 1 : 0}
            label="Group Status"
            name="isActive"
            placeholder="Select Group Status"
            className="w-5/12 sm:w-full"
          />
        </div>

        <div className="flex justify-end items-center space-x-4 mt-12">
          <SecondaryButton text="Cancel" onClick={toggle} />
          <PrimaryButton
            disabled={!required}
            text={`${edit ? "Update" : "Create"} Group`}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
