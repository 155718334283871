import React, { PropsWithChildren } from 'react';
import { Socket, io } from 'socket.io-client';
import { useAppSelector } from '../../hooks';
import { authSelector } from '../../store/slices';
import { WsEvents, WsPilotLocation } from '../../types';

const options = (token: string) => ({
  auth: { token: token },
  withCredentials: true,
  transports: ['websocket', 'polling'],
  extraHeaders: {
    'ngrok-skip-browser-warning': '69420',
  },
});

export const SocketContext = React.createContext<Socket | undefined>(undefined);

export const SocketProvider = ({ children }: PropsWithChildren) => {
  const { is_authenticated } = useAppSelector(authSelector);

  const [socket, setSocket] = React.useState<Socket | undefined>();

  const _handleAuth = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      setSocket(io(`${process.env.REACT_APP_BASE_URL}`, options(accessToken)));

      return accessToken;
    }
    return false;
  };

  const _handleRoom = React.useCallback(async () => {
    if (socket && is_authenticated) {
      if (socket.auth) {
        socket.emit(WsEvents.JOIN_USER);
        socket.emit(WsEvents.JOIN_BACKOFFICE);

        socket.on(WsEvents.PILOT_LOCATION, (data: WsPilotLocation) => {});
      }
    }
  }, [socket, is_authenticated]);

  React.useEffect(() => {
    _handleAuth();
  }, []);

  React.useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        _handleRoom();
      });

      socket.on('disconnect', () => {});

      return () => {
        socket.off('connect');
        socket.off('disconnect');
        socket.off(WsEvents.JOIN_USER);
        socket.off('pong');
      };
    }
  }, [socket, _handleRoom]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
