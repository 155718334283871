import numeral from 'numeral';
import { Cell } from 'react-table';
import { IWalletTransactionsFilters } from '../types';

export const walletTransactionsColumns = [
  {
    Header: 'Reference',
    accessor: 'accountTxRef',
    Cell: ({ row }: Cell) => row.values.accountTxRef ?? '',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ row }: Cell) => `₦${numeral(row.values.amount).format('0,0')}`,
  },
  {
    Header: 'Date Created',
    accessor: 'dateTime',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
];

export const walletTransactionsFilters: IWalletTransactionsFilters = {
  provider: '',
  dateRange: {
    startDate: '',
    endDate: '',
  },
  transactionType: '',
  amount: {
    min: 0,
    max: 0,
  },
};
