import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { createWalletEntry, userSelector } from '../../../store/slices';
import { CreateWalletEntry, IUser, TransactionEntry } from '../../../types';
import { IOnChange, SelectInput } from './select';
import { TextInput } from './inputs';
import { PrimaryButton, SecondaryButton, ToggleButton } from './buttons';

interface UpdateWalletProps {
  close: () => void;
  user?: IUser;
}

export const UpdateWallet = ({ close, user }: UpdateWalletProps) => {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector(userSelector);

  const [payload, setPayload] = React.useState<CreateWalletEntry>({
    amount: 0,
    userId: user?.id ?? 0,
    entry: 'DEBIT',
    description: '',
    reference: '',
  });

  const options = ['DEBIT'];

  const handleUserChange: IOnChange<IUser> = (selectedUser) => {
    setPayload({ ...payload, userId: (selectedUser as IUser).id });
  };

  const handleInputValue = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setPayload({
      ...payload,
      [name]: name === 'amount' ? parseInt(value) : value,
    });
  };

  const processTransaction = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(createWalletEntry({ payload, cb: close }));
  };

  return (
    <div className="bg-white p-6">
      <p className="medium">Update user wallet</p>

      <div className="mt-4 flex space-x-4">
        {options.map((option) => (
          <ToggleButton
            key={option}
            text={option}
            active={payload.entry === option}
            onClick={() =>
              setPayload({ ...payload, entry: option as TransactionEntry })
            }
          />
        ))}
      </div>

      <form onSubmit={processTransaction}>
        {!user && (
          <SelectInput
            singleSelect
            name="user"
            label="Users"
            placeholder="Search for a user"
            options={users.data}
            handleChange={handleUserChange}
            getOptionValue={(user) => user.phoneNumber}
            getOptionLabel={(user) =>
              `${user.firstName} ${user.lastName}, ${user.phoneNumber}`
            }
          />
        )}
        <TextInput
          onChange={handleInputValue}
          value={payload.description}
          placeholder="Enter description"
          name="description"
          label="Description"
        />

        <TextInput
          onChange={handleInputValue}
          value={payload.amount || ''}
          placeholder="Enter amount"
          name="amount"
          label="Amount"
          type="number"
          min={0}
        />

        <div className="flex gap-4 mt-4 xl:mt-0">
          <PrimaryButton
            disabled={
              !(payload.userId && payload.amount && !!payload.description)
            }
            text={`Process Transaction`}
            type="submit"
          />
          <SecondaryButton text="Cancel" type="button" onClick={close} />
        </div>
      </form>
    </div>
  );
};
