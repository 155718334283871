import numeral from 'numeral';
import { FoodItem, OrderItem, ResolvedResponse } from '../../../../../types';
import React from 'react';
import { deleteIcon, edit } from '../../../../../assets/svg';
import { SecondaryButton } from '../../../../components';
import Modal from 'react-modal';
import { FoodItemSelector } from './food-item';

export const OrderItemsSection = ({
  foodItems,
  cartAmount,
  orderItemList,
  calculateCart,
  setOrderItemList,
}: {
  cartAmount: number;
  orderItemList: OrderItem[];
  foodItems?: ResolvedResponse<FoodItem[]>;
  calculateCart: () => number;
  setOrderItemList: (x: OrderItem[]) => void;
}) => {
  const [showItemModal, setShowItemModal] = React.useState(false);
  const [editItem, setEditItem] = React.useState('');

  const editFoodItem = (token: string) => {
    setShowItemModal(true);
    setEditItem(token);
  };

  const closeModal = () => {
    setShowItemModal(false);
    setEditItem('');
  };

  const deleteFoodItem = (token: string) => {
    let tempList = [...orderItemList];
    tempList = tempList.filter((t) => t.token !== token);

    setOrderItemList(tempList);
  };

  return (
    <>
      <section className="section">
        <div className="flex justify-between">
          <div>
            <h3 className="semibold">Order Items</h3>
            <p className="text-sm">Meal Items in this order</p>
          </div>
          <div>
            <p className="text-sm">
              Cart Amount: ₦{numeral(cartAmount).format('0,0')}
            </p>

            {!!orderItemList.length && (
              <button
                className="rounded-lg bg-primary-200 py-1.5 px-3 medium text-xs mt-3 text-primary-700"
                onClick={calculateCart}
                type="button"
              >
                Calculate amount
              </button>
            )}
          </div>
        </div>

        {orderItemList.map((item, index) => (
          <div
            className="border border-neutral-300 rounded p-4 mt-4 flex justify-between"
            key={`${item.foodItem} ${index}`}
          >
            <div className="w-9/12">
              <p className="text-neutral-800 bold">{`x${item.quantity} ${item.name}`}</p>
              <p className="mt-2 text-sm">
                <span className="semibold">Options: </span>
                {item.options.map((o) => `x${o.quantity} ${o.name}`).join(', ')}
              </p>
              <p className="mt-2 text-sm">
                <span className="semibold">Customizations: </span>
                {item.customisations.map((o) => `${o.name}`).join(', ')}
              </p>
            </div>

            <div className="flex space-x-3">
              <button
                type="button"
                onClick={() => editFoodItem(item.token ?? '')}
              >
                <img src={edit} alt="edit" />
              </button>

              <button
                type="button"
                onClick={() => deleteFoodItem(item.token ?? '')}
              >
                <img src={deleteIcon} alt="delete" />
              </button>
            </div>
          </div>
        ))}

        <SecondaryButton
          type="button"
          text="Add food item"
          classNames="w-full mt-8"
          onClick={() => setShowItemModal(true)}
        />
      </section>

      <Modal
        isOpen={showItemModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className={`w-11/12 sm:w-8/12 xl:w-5/12 default-modal`}
        overlayClassName="modal-overlay"
      >
        <FoodItemSelector
          edit={editItem}
          close={closeModal}
          orderItemList={orderItemList}
          foodItems={foodItems?.data ?? []}
          setOrderItemList={setOrderItemList}
        />
      </Modal>
    </>
  );
};
