import { FC, SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { close as closeIcon } from '../../../assets/svg';
import { notificationTypes } from '../../../constants';
import { useAppDispatch } from '../../../hooks';
import { sendNotification, userSelector } from '../../../store/slices';
import { ICreateNotification, NotificationOption } from '../../../types';
import { PrimaryButton, PrimaryInput, TextArea, ToggleButton } from '../shared';

interface INotifications {
  toggle: () => void;
  selectedRows: string[];
  singleUser?: boolean;
}

export const SendNotifications: FC<INotifications> = ({
  toggle,
  selectedRows,
  singleUser,
}) => {
  const dispatch = useAppDispatch();
  const { users } = useSelector(userSelector);

  const [activeType, setActiveType] = useState<NotificationOption>('push');
  const [payload, setPayload] = useState<ICreateNotification>({
    content: '',
    subject: '',
  });

  const typeValueMapper = {
    sms: 'phoneNumber',
    email: 'email',
    push: 'pushNotifications',
  };

  const typeKeyMapper = {
    sms: 'phones',
    email: 'emails',
    push: 'receivers',
  };

  const onGetInputValue = (
    event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const returnReceiver = (selectedRows: string[]) => {
    const receiverKey = typeKeyMapper[activeType as keyof typeof typeKeyMapper];
    const receiverValue =
      activeType !== 'push' &&
      typeValueMapper[activeType as keyof typeof typeValueMapper];

    let value: string[] = [];
    for (let i = 0; i < selectedRows.length; i++) {
      var user = users.data.find(
        (user) => user.id.toString() === selectedRows[i]
      );

      if (user) {
        if (activeType !== 'push') {
          const getValue = user[receiverValue as keyof typeof user]!.toString();
          value.push(getValue);
        } else {
          user.pushNotifications.forEach((object) => {
            for (let key in object) {
              value.push(object[key as keyof typeof object]);
            }
          });
        }
      }
    }
    return { [receiverKey]: value };
  };

  const _sendNotifications = (e?: SyntheticEvent<HTMLFormElement>) => {
    if (!(payload.content.length > 0 && Object.keys(selectedRows).length > 0))
      return;
    e?.preventDefault();

    const receiver = returnReceiver(Object.keys(selectedRows));

    dispatch(
      sendNotification({
        payload: { ...receiver, ...payload },
        cb: toggle,
        type: activeType,
      })
    );
  };

  const sendToAll = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();

    dispatch(
      sendNotification({
        sendToAll: true,
        payload: { ...payload },
        cb: toggle,
        type: activeType,
      })
    );
  };

  return (
    <div className="bg-white p-6">
      <div className="flex justify-between items-center">
        <p className="bold text-2xl">Send Notifications</p>
        <button onClick={toggle}>
          <img src={closeIcon} alt="close" />
        </button>
      </div>

      <form className="mt-5" onSubmit={_sendNotifications}>
        <div className="flex flex-wrap justify-between items-end">
          <div className="flex items-center flex-wrap">
            {notificationTypes.map(({ name, value }) => (
              <ToggleButton
                key={value}
                active={activeType === value}
                onClick={() => setActiveType(value as NotificationOption)}
                text={name}
              />
            ))}
          </div>

          {activeType !== 'sms' && (
            <PrimaryInput
              onChange={onGetInputValue}
              placeholder="Enter Subject"
              required
              name="subject"
              label="Subject"
              className="w-5/12 sm:w-full"
            />
          )}
          <TextArea
            onChange={onGetInputValue}
            placeholder="Enter Content"
            name="content"
            label="The body of the notification"
            className="w-6/12 sm:w-full"
            required
          />
        </div>

        <div className="flex justify-end items-center space-x-4 mt-12">
          {Object.keys(selectedRows).length > 0 && (
            <PrimaryButton
              disabled={!(payload.content.length > 0)}
              text="Send"
              onClick={_sendNotifications}
              type="submit"
            />
          )}
          {!singleUser && (
            <PrimaryButton
              disabled={!(payload.content.length > 0)}
              text="Send to All"
              onClick={sendToAll}
            />
          )}
        </div>
      </form>
    </div>
  );
};
