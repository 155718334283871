import { IOrder, IVariant, OrderItem } from '../types';

export const returnVariantLabel = (id: number, variants: IVariant[]) => {
  let variantLabel;
  for (let i = 0; i < variants.length; i++) {
    if (variants[i].id === id) {
      variantLabel = variants[i].name;
    }
  }
  return variantLabel;
};

export const returnTimeStamps = (order: IOrder | null) => {
  return order
    ? [
        { title: 'Time Paid', value: order.timePaid },
        { title: 'Time Placed', value: order.timePlaced },
        { title: 'Time Preparing', value: order.timePreparing },
        { title: 'Time Prepared', value: order.timePrepared },
        { title: 'Time Ready', value: order.timeReady },
        { title: 'Time Accepted', value: order.timeAccepted },
        { title: 'Time Picked Up', value: order.timePickedUp },
        { title: 'Time Arrived', value: order.timeArrived },
        { title: 'Time Delivered', value: order.timeDelivered },
      ]
    : [];
};

export const calculateItemPrice = (order: OrderItem) => {
  const itemPrice = order.price;
  const options = order.options.map((o) => o.quantity * o.price);
  const optionPrice = options.reduce((acc, price) => acc + price, 0);

  const customizations = order.customisations.map((o) => o.quantity * o.price);
  const customizationPrice = customizations.reduce(
    (acc, price) => acc + price,
    0
  );

  const customItemPrice = (itemPrice ?? 0) + optionPrice + customizationPrice;

  return customItemPrice * order.quantity;
};
