import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { transactionsApi } from '../../api';
import {
  CreateWalletEntry,
  IPagination,
  IWalletTransactions,
} from '../../types';
import { genFilterQuery } from '../../helpers';
import { toast } from 'react-toastify';

interface ITransactionState {
  loading: boolean;
  transactions: any[];
  walletTransactions: {
    data: IWalletTransactions[];
    pagination: IPagination | null;
  };
}

const initialState: ITransactionState = {
  loading: false,
  transactions: [],
  walletTransactions: { data: [], pagination: null },
};

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllTransactions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTransactions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.transactions = payload;
    });
    builder.addCase(getAllTransactions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getWalletTransactions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWalletTransactions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.walletTransactions = {
        data: payload.data,
        pagination: payload.pagination,
      };
    });
    builder.addCase(getWalletTransactions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createWalletEntry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createWalletEntry.fulfilled, (state, { payload }) => {
      state.loading = false;
      toast.success("User's wallet updated");
    });
    builder.addCase(createWalletEntry.rejected, (state) => {
      state.loading = false;
    });
  },
});

const getAllTransactions = createAsyncThunk(
  'getAllTransactions',
  async (params: { page?: number; cb?: () => void }) => {
    try {
      const { page, cb } = params;
      const query = genFilterQuery();
      const { data } = await transactionsApi.getAllTransactions({
        query,
        page,
      });
      cb && cb();

      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getWalletTransactions = createAsyncThunk(
  'getWalletTransactions',
  async (params: { page?: number; cb?: () => void }) => {
    try {
      const { page, cb } = params;
      const query = genFilterQuery();

      const { data } = await transactionsApi.getWalletTransactions({
        query,
        page,
      });

      cb && cb();

      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const createWalletEntry = createAsyncThunk(
  'createWalletEntry',
  async (params: { payload: CreateWalletEntry; cb?: () => void }) => {
    try {
      const { payload, cb } = params;

      const { data } = await transactionsApi.createWalletEntry(payload);

      cb && cb();

      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const transactionSelector = (state: RootState) => state.transactions;
export { getAllTransactions, getWalletTransactions, createWalletEntry };

export default transactionSlice.reducer;
