import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { branchApi } from '../../api';
import { RootState } from '..';
import { Branch, CreateBranch, IGroup, UpdateBranch } from '../../types';
import { toast } from 'react-toastify';

interface BranchState {
  branches: Branch[];
  loading: boolean;
  branchMenu: { name: string; groups: IGroup[] } | null;
}

const initialState: BranchState = {
  loading: false,
  branches: [],
  branchMenu: null,
};

export const branchSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllBranches.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBranches.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.branches = payload;
    });
    builder.addCase(getAllBranches.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createBranch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBranch.fulfilled, (state) => {
      state.loading = false;
      toast.success('Branch created successfully');
    });
    builder.addCase(createBranch.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBranchMenu.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBranchMenu.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.branchMenu = payload;
    });
    builder.addCase(getBranchMenu.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateBranch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBranch.fulfilled, (state) => {
      state.loading = false;
      toast.success('Branch updated successfully');
    });
    builder.addCase(updateBranch.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateBranchName.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBranchName.fulfilled, (state) => {
      state.loading = false;
      toast.success('Branch name updated successfully');
    });
    builder.addCase(updateBranchName.rejected, (state) => {
      state.loading = false;
    });
  },
});

const getAllBranches = createAsyncThunk('getAllBranches', async () => {
  try {
    const { data } = await branchApi.getAllBranches();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const createBranch = createAsyncThunk(
  'createBranch',
  async (params: { payload: CreateBranch; cb: () => void }) => {
    try {
      const { cb, payload } = params;
      const { data } = await branchApi.createBranch(payload);
      cb && cb();

      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getBranchMenu = createAsyncThunk(
  'getBranchMenu',
  async (param: string) => {
    try {
      const { data } = await branchApi.getBranchMenu(param);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const updateBranch = createAsyncThunk(
  'updateBranch',
  async (params: { payload: UpdateBranch; id: string; cb?: () => void }) => {
    try {
      const { cb, id, payload } = params;
      const { data } = await branchApi.updateBranch(payload, id);
      cb && cb();

      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const updateBranchName = createAsyncThunk(
  'updateBranchName',
  async (params: {
    payload: { name: string };
    id: string;
    cb?: () => void;
  }) => {
    try {
      const { cb, id, payload } = params;
      const { data } = await branchApi.updateBranchName(payload, id);
      cb && cb();

      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const branchSelector = (state: RootState) => state.branch;
export {
  getAllBranches,
  createBranch,
  getBranchMenu,
  updateBranch,
  updateBranchName,
};

export default branchSlice.reducer;
