import { IGeneralApi } from '../types';
import { instance } from './instance';

export const generalApi: IGeneralApi = {
  getAllAddresses: async () => await instance.get('/address/all/'),

  getLocations: async () => await instance.get(`/location/all/`),

  getGiftCards: async () => await instance.get(`/giftcards/all/`),
};
