import { FC } from "react";
import { search } from "../../../assets/svg";
import { useAppSelector } from "../../../hooks";
import { authSelector } from "../../../store/slices";

export const TopBar: FC = () => {
  const { user } = useAppSelector(authSelector);

  return (
    <div className="bg-white flex py-3 justify-around items-center">
      <div className="relative w-8/12 lg:w-5/12">
        <div className="flex items-center absolute h-full left-3">
          <img src={search} alt="search" className="" />
        </div>
        <input
          type="text"
          className="bg-neutral-100 py-3.5 pl-10 px-4 rounded-lg placeholder-neutral-600 focus:outline-none w-full text-sm"
          placeholder="Search order, brand, driver"
        />
      </div>
      <div className="flex items-center space-x-2">
        <div className="rounded-full h-9 w-9 relative bg-dark-blue">
          <p className="absolute flex items-center justify-center bold h-full w-full text-white">
            {user?.firstName?.charAt(0)}
            {user?.lastName?.charAt(0)}
          </p>
        </div>
        <p className="hidden lg:block">
          {user?.firstName} {user?.lastName}
        </p>
      </div>
    </div>
  );
};
