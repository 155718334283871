import { useNavigate, useParams } from 'react-router-dom';
import { useFetch } from './useFetch';
import { FoodGroup, FoodItem, Option, ResolvedResponse } from '../types';
import React from 'react';
import { IOnChange } from '../views/components';
import { mealsApi } from '../api';
import { toast } from 'react-toastify';
import { parseError } from '../helpers';

export const useFoodGroups = () => {
  const navigate = useNavigate();
  let { action, id } = useParams();
  const { useFetchFoodItem, useFetchFoodGroup, useFetchOptions } = useFetch();

  const { data: optionList } = useFetchOptions();

  const { data: foodItems, isLoading: loadingFoodItems } = useFetchFoodItem<
    ResolvedResponse<FoodItem[]>
  >(undefined, { count: 500 });

  const { data: foodGroup, isLoading: loadingFoodGroup } = useFetchFoodGroup<
    ResolvedResponse<FoodGroup>
  >(id, {
    enabled: !!id,
  });

  const [loading, setLoading] = React.useState(false);
  const [required, setRequired] = React.useState(false);
  const [options, setOptions] = React.useState<Option[]>([]);
  const [modal, setModal] = React.useState(false);
  const [payload, setPayload] = React.useState<
    Pick<
      FoodGroup<string, string, string>,
      | 'name'
      | 'minimumSelection'
      | 'maximumSelection'
      | 'foodItem'
      | 'customisations'
      | 'position'
    >
  >({
    name: '',
    maximumSelection: null,
    minimumSelection: null,
    position: 0,
  });

  const goBack = () => {
    navigate(-1);
  };

  const onGetInputValue = (
    event: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const handleChangeCustomization: IOnChange<FoodItem> = (item) => {
    if (item)
      setPayload({
        ...payload,
        customisations: (item as FoodItem[]).map((i) => i.id),
      });
  };

  const handleChangeOptions: IOnChange<Option> = (item) => {
    setOptions([...options, ...(item as Option[])]);
  };

  const handleChangeFoodItems: IOnChange<FoodItem> = (item) => {
    if (item)
      setPayload({
        ...payload,
        foodItem: (item as FoodItem[]).map((i) => i.id),
      });
  };

  const createFoodGroup = async (e?: React.SyntheticEvent<HTMLFormElement>) => {
    e?.stopPropagation();
    e?.preventDefault();

    setLoading(true);

    try {
      if (id) {
        const finalPayload = { ...payload, options: options.map((o) => o.id!) };
        await mealsApi.updateFoodGroup(id, finalPayload);

        toast.success('Food group updated!');
      } else {
        const finalPayload = { ...payload, options };
        await mealsApi.createFoodGroup(finalPayload);

        toast.success('Food item created!');
      }
      navigate(-1);
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      setLoading(false);
    }
  };

  const removeOption = (index: number) => {
    const tempOptions = [...options];

    tempOptions.splice(index, 1);
    setOptions(tempOptions);
  };

  React.useEffect(() => {
    const { name } = payload;

    setRequired(name.length > 0);
  }, [payload]);

  React.useEffect(() => {
    if (foodGroup && foodGroup.data && id) {
      const group = foodGroup.data;

      setPayload({
        name: group.name,
        maximumSelection: group.maximumSelection,
        minimumSelection: group.minimumSelection,
        position: group.position,
        customisations: group.customisations?.map((i) => i.id!),
        foodItem: group.foodItem?.map((i) => i.id!),
      });

      setOptions(group.options);
    }
  }, [foodGroup, id]);

  return {
    id,
    modal,
    action,
    payload,
    loading,
    options,
    required,
    foodItems,
    foodGroup,
    optionList,
    loadingFoodItems,
    loadingFoodGroup,
    goBack,
    navigate,
    setModal,
    setOptions,
    removeOption,
    createFoodGroup,
    onGetInputValue,
    handleChangeOptions,
    handleChangeFoodItems,
    handleChangeCustomization,
  };
};
