import { useNavigate, useParams } from 'react-router-dom';
import {
  branchSelector,
  brandsSelector,
  createBranch,
  getAllBranches,
  getBranchMenu,
  kitchensSelector,
  mealSelector,
  updateBranch,
  updateBranchName,
  variantSelector,
} from '../store/slices';
import { useAppDispatch, useAppSelector } from './redux.hooks';
import { SyntheticEvent, useEffect, useState } from 'react';
import {
  CreateBranch,
  IBrand,
  IKitchen,
  IMeal,
  IVariant,
  UpdateBranch,
} from '../types';
import { IOnChange } from '../views/components';

export const useBranch = (page?: boolean) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { loading, branches, branchMenu } = useAppSelector(branchSelector);
  const { kitchens } = useAppSelector(kitchensSelector);
  const { brands } = useAppSelector(brandsSelector);
  const { variants } = useAppSelector(variantSelector);
  const { meals } = useAppSelector(mealSelector);

  let { id } = useParams();

  const [payload, setPayload] = useState<CreateBranch>({
    kitchenId: 0,
    brandId: 0,
    name: '',
  });
  const [updatePayload, setUpdatePayload] = useState<UpdateBranch>({
    mealOverrides: [],
    variantOverrides: [],
  });
  const [modal, setModal] = useState({ visible: false, data: {} });
  const [branchMeals, setBranchMeals] = useState<IMeal[]>([]);
  const [branchVariants, setBranchVariants] = useState<IVariant[]>([]);
  const [branchName, setBranchName] = useState('');

  const createBranchPage = () => {
    navigate(`/dashboard/branch/create`);
  };

  const _editBranch = (slug: string, e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (slug) {
      return navigate(`/dashboard/branch/edit/${slug}`);
    }
    return navigate(`/dashboard/branch/create`);
  };

  const branchPage = (slug: string) => {
    navigate(`/dashboard/branches/${slug}`);
  };

  const branchesPage = () => {
    navigate(`/dashboard/branches`);
  };

  const onChangeKitchen: IOnChange<IKitchen> = (kitchen) => {
    if (kitchen)
      setPayload({ ...payload, kitchenId: (kitchen as IKitchen).id });
  };

  const onChangeBrand: IOnChange<IBrand> = (brand) => {
    if (brand) setPayload({ ...payload, brandId: (brand as IBrand).id });
  };

  const _createBranch = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();
    dispatch(createBranch({ payload, cb: branchesPage }));
  };

  const handleOverrideChange = (
    e: SyntheticEvent<HTMLSelectElement | HTMLInputElement>,
    override?: 'mealOverrides' | 'variantOverrides'
  ) => {
    let temp: Record<string, any> = { ...updatePayload };

    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    if (override) {
      if (override === 'mealOverrides') {
        temp.mealOverrides[0][name === 'price' ? 'basePrice' : name] =
          name === 'price' ? parseInt(value) : (value as any);
      }

      if (override === 'variantOverrides') {
        temp.variantOverrides[0][name] =
          name === 'price' ? parseInt(value) : (value as any);
      }
    }

    setUpdatePayload(temp as UpdateBranch);
  };

  const _updateBranch = (
    e?: SyntheticEvent<HTMLFormElement>,
    payload?: UpdateBranch
  ) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (id)
      dispatch(
        updateBranch({
          payload: payload ?? updatePayload,
          cb: () => {
            branchesPage();
            dispatch(getAllBranches());
            id && dispatch(getBranchMenu(id));
          },
          id,
        })
      );
  };

  const _updateBranchName = (e?: SyntheticEvent<HTMLFormElement>) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (id)
      dispatch(
        updateBranchName({
          payload: { name: branchName },
          cb: () => {
            branchesPage();
            dispatch(getAllBranches());
          },
          id,
        })
      );
  };

  const openModal = (
    event: SyntheticEvent<HTMLButtonElement>,
    id?: number,
    name?: string
  ) => {
    event.stopPropagation();
    if (id && name) {
      let temp;

      if (name === 'mealOverrides') {
        const meal = branchMeals.find((meal) => meal.id === id);

        if (meal) {
          temp = {
            ...updatePayload,
            mealOverrides: [
              {
                mealId: meal.id,
                basePrice: meal.basePrice,
                status: meal.status,
              },
            ],
          };
          setModal({ visible: true, data: temp });
        }
      } else if (name === 'variantOverrides') {
        const variant = branchVariants.find((variant) => variant!.id === id);

        if (variant) {
          temp = {
            ...updatePayload,
            variantOverrides: [
              {
                variantId: variant.id,
                price: variant.price,
                status: variant.status,
              },
            ],
          };
          setModal({ visible: true, data: temp });
        }
      }
    }
  };

  const closeModal = () => {
    setUpdatePayload({
      ...updatePayload,
      mealOverrides: [],
      variantOverrides: [],
    });

    setModal({ visible: false, data: {} });
  };

  const toggleAvailability = (
    module: 'mealOverrides' | 'variantOverrides',
    active: boolean,
    item: any
  ) => {
    if (id) {
      if (module === 'mealOverrides') {
        dispatch(
          updateBranch({
            payload: {
              mealOverrides: [
                {
                  mealId: parseInt(`${item.id}`),
                  status: active ? 'OUT_OF_STOCK' : 'ACTIVE',
                  basePrice: item.basePrice,
                },
              ],
              variantOverrides: [],
            },
            cb: () => id && dispatch(getBranchMenu(id)),
            id,
          })
        );
      }

      if (module === 'variantOverrides') {
        dispatch(
          updateBranch({
            payload: {
              variantOverrides: [
                {
                  variantId: parseInt(`${item.id}`),
                  status: active ? 'OUT_OF_STOCK' : 'ACTIVE',
                  price: item.price,
                },
              ],
              mealOverrides: [],
            },
            cb: () => id && dispatch(getBranchMenu(id)),
            id,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (id && page) {
      dispatch(getBranchMenu(id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (branchMenu) {
      setBranchMeals(branchMenu.groups.map((group) => group.meals).flat());

      setBranchVariants(
        branchMenu.groups
          .map((group) =>
            group.meals
              .map((meal) =>
                meal.choices?.map((choice) => choice.variants).flat()
              )
              .flat()
          )
          .flat() as IVariant[]
      );

      setBranchName(branchMenu.name ?? '');
    }
    // eslint-disable-next-line
  }, [branchMenu]);

  return {
    modal,
    loading,
    branches,
    branchMenu,
    branchMeals,
    branchVariants,
    kitchens,
    brands,
    payload,
    updatePayload,
    variants,
    meals,
    branchName,
    createBranchPage,
    _editBranch,
    _createBranch,
    _updateBranch,
    branchPage,
    branchesPage,
    onChangeKitchen,
    onChangeBrand,
    setPayload,
    setUpdatePayload,
    handleOverrideChange,
    openModal,
    closeModal,
    toggleAvailability,
    setBranchName,
    _updateBranchName,
  };
};
