/** @format */

import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { storeRef } from '../index';
import { refresh } from '../store/slices';

interface AppError extends AxiosError {
  data: any;
}

const _handleError = async (
  error: AppError,
  tries?: number,
  altMessage?: string
) => {
  if (error?.response?.status === 400) {
    const temp: any = error?.response?.data || error;

    if (typeof error === 'string') {
      return toast.error(`${error} please check your internet`);
    }

    delete temp['status_code'];

    if (typeof temp !== 'object') {
      toast.error(`Something went wrong, please try again`);
    } else {
      const toastId = '400-toast';
      Object.keys(temp).forEach((key) => {
        temp[key][0] &&
          toast.error(
            typeof temp[key] === 'string'
              ? temp[key]
              : temp[key][0] || altMessage || 'An error occurred, try again.',
            { toastId, position: toast.POSITION.TOP_RIGHT }
          );
      });
    }
    return;
  }

  if (error?.response?.status === 403 || error?.response?.status === 404) {
    let errorMessage;

    if (!(error?.response?.data as any)?.message) {
      errorMessage = 'Something went wrong, please try again later';
    } else {
      typeof (error?.response?.data as any)?.message === 'string'
        ? (errorMessage = (error?.response?.data as any)?.message)
        : (errorMessage = (error?.response?.data as any)?.message[0]);
    }

    return toast.error(errorMessage);
  }

  if (error?.response?.status === 401 && typeof tries === 'number') {
    if (error.response.data) toast.error((error.response.data as any).message);

    if (altMessage !== 'refresh') {
      if (tries <= 3) {
        toast.error('Unauthorized, your token will be refreshed');
        return storeRef.dispatch(refresh());
      } else {
        toast.error(
          'Unauthorized, please try again with different credentials'
        );
      }
    }
  }

  if (error?.response?.status === 500) {
    toast.error(
      'There was an error completing your request. Please try again later'
    );
    return;
  }

  const customId = '429-toast';
  if (error?.response?.status === 429) {
    toast.error('Too many requests, please try again later', {
      position: toast.POSITION.TOP_RIGHT,
      toastId: customId,
    });
    return;
  }
};

const saveSession = ({
  accessToken,
  expiresIn,
  refreshToken,
}: {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
}) => {
  localStorage.setItem('accessToken', accessToken as string);
  // localStorage.setItem("login_time", JSON.stringify(new Date().getTime()));
  localStorage.setItem('refreshToken', refreshToken as string);
  // localStorage.setItem("expiresIn", expiresIn.toString());
};

export { _handleError, saveSession };
